class Logs {
  constructor({
    id,
    _id,
    datetime,
    data,
    description,
    type,
    uid,
    hash,
    hash_blockchain,
  }) {
    this.id = id || _id;
    this.datetime = datetime;
    this.data = data;
    this.description = description;
    this.type = type;
    this.uid = uid;
    this.hash = hash;
    this.hash_blockchain = hash_blockchain;
  }
}

export default Logs;
