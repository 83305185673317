import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "suppliers",
  initialState: {
    list: [],
    bySubcategory: [],
  },
  reducers: {
    setSuppliers: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
    setSuppliersBySubcategory: (state, { payload: bySubcategory }) => {
      return {
        ...state,
        bySubcategory,
      };
    },
    selectSupplier: (state, { payload: selected }) => {
      return {
        ...state,
        selected,
      };
    },
    deleteSupplier: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },
    countSuppliers: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
  },
});

export const {
  setSuppliers,
  setSuppliersBySubcategory,
  selectSupplier,
  deleteSupplier,
  setSuppliersByUser,
  countSuppliers
} = slice.actions;

export default slice.reducer;

export const getSuppliersSelector = (store) => store.suppliers.list;
export const getSuppliersBySubcategorySelector = (store) =>
  store.suppliers.bySubcategory;
export const getSelectedSuppliersSelector = (store) => store.suppliers.selected;
export const getDeleteSupplierSelector = (store) => store.suppliers.id;
export const getSuppliercOuntSelector = (store) => store.shopifyDistances.count;
