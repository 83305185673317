import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getCostCenters(params) {
  return await new API(
    URL.setParams(URL.buildUrl("costCenters"), params)
  ).get();
}

export async function postCostCenters(data) {
  return await new API(URL.setParams(URL.buildUrl("costCenters"))).post(data);
}

export async function deleteCostCenters(id) {
  return await new API(URL.setId(URL.buildUrl("costCenters"), id)).delete();
}

export async function editCostCenter(id, data) {
  const url = URL.setId(URL.buildUrl("costCenters"), id);
  return await new API(url).put(data);
}
