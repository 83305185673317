import React, { useState } from "react";
import { Form, message } from "antd";
import {
  MailOutlined,
  UserOutlined,
  IdcardOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
import { postCompany } from "../Infrastructure/Companies.service";
import Modal from "../../Modal/Modal";
import { AddIcon, CustomFormItem } from "../../Utils/UI/Text";
import CustomError from "../../Utils/Domain/CustomError";
export default function NewCompany({ getCompanies }) {
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    name: "",
    lastname: "",
    email: "",
  });
  const [formErrors, setFormErrors] = useState({});

  async function handleSubmit() {
    const { name, email, lastname } = values;
    try {
      await postCompany({ name, email, lastname });
      setValues({ ...values, visible: false });
      form.setFieldsValue({ name: "", lastname: "", email: "" });
      message.success("Se agregó una nueva Compañía");
      getCompanies();
      return true;
    } catch (error) {
      console.log("Error completo:", error);

      const customError = new CustomError(error);
      console.log("Mensaje de error procesado:", customError.message);

      message.error(customError.message);

      if (customError.message.includes("user to be registered")) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: customError.message,
        }));
      }
      return false;
    }
  }

  const styles = {
    formCompany: {
      fontFamily: "MontserratRegular",
      height: "100%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      flexDirection: "column",
    },
  };

  return (
    <>
      <Modal
        children={
          <Form
            responsive={"true"}
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            style={styles.formCompany}
            initialValues={values}
            onValuesChange={(changedValues, allValues) => {
              const updatedValues = { ...allValues };

              if (changedValues.name) {
                updatedValues.name = changedValues.name.replace(
                  /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                  ""
                );
              }

              if (changedValues.lastname) {
                updatedValues.lastname = changedValues.lastname.replace(
                  /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s'-]/g,
                  ""
                );
              }

              setValues(updatedValues);
              form.setFieldsValue(updatedValues);
            }}
          >
            <CustomFormItem
              label="Correo Electrónico"
              name="email"
              placeholder="Correo Electrónico"
              maxLength={50}
              type="email"
              rules={[
                { type: "email", message: "Email no válido" },
                { required: true, message: "Ingresa un valor" },
              ]}
              showCounter
              tooltipText="Ingrese un correo válido. Ejemplo: usuario@correo.com"
              iconSuffix={<MailOutlined />}
              style={{ width: "100%" }}
              validateStatus={formErrors.email ? "error" : ""}
              help={formErrors.email}
            />

            <CustomFormItem
              label="Nombre"
              name="name"
              placeholder="Nombre"
              maxLength={25}
              customRules={[
                {
                  pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                  message: "Solo se permiten letras y espacios",
                },
              ]}
              showCounter
              tooltipText="Solo se permiten letras y espacios"
              iconSuffix={<UserOutlined />}
              style={{ width: "100%" }}
              validateStatus={formErrors.name ? "error" : ""}
              help={formErrors.name}
            />

            <CustomFormItem
              label="Apellido"
              name="lastname"
              placeholder="Apellido"
              maxLength={25}
              customRules={[
                {
                  pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s'-]+$/,
                  message:
                    "Solo se permiten letras, espacios, apóstrofes y guiones.",
                },
              ]}
              showCounter
              tooltipText="Solo se permiten letras, espacios, apóstrofes y guiones"
              iconSuffix={<IdcardOutlined />}
              style={{ width: "100%" }}
              validateStatus={formErrors.lastname ? "error" : ""}
              help={formErrors.lastname}
            />
          </Form>
        }
        icon={<AddIcon />}
        style={{
          marginLeft: "12px",
        }}
        title="Nombre de la Categoría"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
        PlusCircleOutlined={<PlusCircleOutlined />}
      />
    </>
  );
}
