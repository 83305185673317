import React, { useState, useEffect } from "react";
import { Form, message } from "antd";
import { PlusCircleOutlined, UserOutlined } from "@ant-design/icons";
import Modal from "../../Modal/Modal";
import { getCategories } from "../Application/Categories.business";
import { postCategories } from "../Infrastructure/Categories.service";
import { AddIcon, CustomFormItem, CustomFormSelect } from "../../Utils/UI/Text";
import CustomError from "../../Utils/Domain/CustomError";

const CategoriesDetail = () => {
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    name: "",
    scope: "",
  });

  useEffect(() => {
    getCategories();
  }, [values]);
  const [formErrors, setFormErrors] = useState({});

  const handleSelectorChange = (value) => {
    console.log(`selected ${value}`);
    setValues({
      ...values,
      scope: value,
    });
  };

  const handleSubmit = async () => {
    const { name, scope } = values;
    try {
      await postCategories({
        name,
        scope,
      });
      setValues({ ...values });
      form.setFieldsValue({ name: "", scope: "" });

      message.success("Se agregó una nueva Categoría");
      getCategories();
      return true;
    } catch (error) {
      console.log("Error completo:", error);

      const customError = new CustomError(error);
      console.log("Mensaje de error procesado:", customError.message);

      message.error(customError.message);

      if (customError.message.includes("Email already registered")) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: customError.message,
        }));
      }
      return false;
    }
  };

  return (
    <>
      <Modal
        children={
          <Form
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            onValuesChange={(changedValues, allValues) => {
              const updatedValues = { ...allValues };

              if (changedValues.name) {
                updatedValues.name = changedValues.name.replace(
                  /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                  ""
                );
              }

              setValues(updatedValues);
              form.setFieldsValue(updatedValues);
            }}
          >
            <CustomFormSelect
              label="Scope"
              name="scope"
              value={values.scope}
              onChange={handleSelectorChange}
              placeholder="Seleccione una opción"
              options={[
                { value: "scope 1", label: "Scope 1" },
                { value: "scope 2", label: "Scope 2" },
                { value: "scope 3", label: "Scope 3" },
              ]}
              rules={[{ required: true, message: "Seleccione una opción" }]}
              tooltipText="Seleccione el alcance del proyecto"
            />
            <CustomFormItem
              label="Nombre"
              name="name"
              placeholder="Ingrese su nombre"
              maxLength={25}
              rules={[
                {
                  required: true,
                  message: "Nombre no válido",
                  pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                },
              ]}
              showCounter
              tooltipText="Solo se permiten letras y espacios."
              iconSuffix={<UserOutlined />}
              hasFeedback
              style={{ width: "100%" }}
              validateStatus={formErrors.name ? "error" : ""}
              help={formErrors.name}
            />
          </Form>
        }
        icon={<AddIcon />}
        style={{
          marginLeft: "12px",
        }}
        title="Agregar Categoría"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
        PlusCircleOutlined={<PlusCircleOutlined />}
      />
    </>
  );
};

export default CategoriesDetail;
