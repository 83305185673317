import React, { useEffect } from "react";
import { useSelector } from "react-redux";
import { Space, Form, message } from "antd";
import AreasEdit from "./AreasEdit";

import InterfaceTable from "../../Table/InterfaceTable";
import { getAreas, deleteArea } from "../Application/Areas.business";
import { getAreasSelector } from "../Infrastructure/Areas.reducer";
import AreasAssociation from "./AreasAssociation";
import { DeleteIcon, ModalDeleteConfirm } from "../../Utils/UI/Text";

const AreasBoard = () => {
  const areas = useSelector(getAreasSelector);
  const [form] = Form.useForm();

  useEffect(() => {
    getAreas();
  }, []);

  const handleDelete = async (id) => {
    try {
      ModalDeleteConfirm({
        title: "¿Está seguro de eliminar un área",
        messageOnOk: "Se eliminó el área",
        onConfirm: () => deleteArea(id),
        onCancel: () => console.log("Cancelado"),
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };
  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <>
            <Space size="middle">
              <AreasEdit record={record} form={form} />

              <AreasAssociation record={record} form={form} />

              <DeleteIcon
                TooltipDelete="Eliminar un área "
                onClick={() => handleDelete(record.id)}
              />
            </Space>
          </>
        );
      },
    },
  ];
  return <InterfaceTable rowKey="id" data={areas} columns={columns} />;
};

export default AreasBoard;
