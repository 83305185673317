import store from "../../Redux/store";
import {
  setTransportTypes,
  countTransportTypes,
  deleteTransportType,
} from "./TransportTypes.reducer";

export function saveListTransportTypes(records) {
  store.dispatch(setTransportTypes(records));
}

export function saveCounTransportTypes(count) {
  store.dispatch(countTransportTypes(count));
}

export function deleteSelectedTransportType(id) {
  store.dispatch(deleteTransportType(id));
}
