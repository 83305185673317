import { getEquivalentCodesRepository,updateEquivalentCodesRepository, deleteEquivalenceCodesRepository} from "../Infrastructure/EquivalentCodes.repository";

export async function getEquivalentCodesLogic(params) {
  return getEquivalentCodesRepository(params);
}
export async function updateEquivalentCodesLogic(id, params) {
  return updateEquivalentCodesRepository(id, params);
}

export async function deleteEquivalenceCodeLogic(id) {
  return deleteEquivalenceCodesRepository(id);
}