import {
  saveListTransportTypes,
  saveCounTransportTypes,
  deleteSelectedTransportType,
} from "../Infrastructure/TransportTypes.store";
import {
  getTransportTypesLogic,
  updateTransportTypeLogic,
  deleteTransportTypeLogic,
} from "./TransportTypes.logic";

export async function getTransportTypes(params) {
  const { count, resources: transportTypes } = await getTransportTypesLogic(
    params
  );
  saveListTransportTypes(transportTypes);
  saveCounTransportTypes(count);
}

export async function updateTransportType(id, params) {
  await updateTransportTypeLogic(id, params);
}

export async function deleteTransportType(id) {
  await deleteTransportTypeLogic(id);
  deleteSelectedTransportType(id);
}
