import { useState, useEffect } from "react";
import InterfaceTable from "../../Table/InterfaceTable";
import ShopifyDistancesDetail from "./ShopifyDistancesDetail";
import { message, Space } from "antd";
import Formatter from "../../Data/Domain/Formatter";
import { deleteShopifyDistances } from "../Application/ShopifyDistances.business";
import { DeleteIcon, ModalDeleteConfirm } from "../../Utils/UI/Text";

const ShopifyDistancesBoard = ({
  shopifyDistances,
  getShopifyDistances,
  totalCounShopifyDistances,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchData({ page: values.page, limit: values.limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCounShopifyDistances]);

  const handleDelete = async (id) => {
    try {
      ModalDeleteConfirm({
        title: "¿Está seguro de eliminar esta distancia de Shopify?",
        messageOnOk: "Se eliminó la distancia de Shopify correctamente.",
        onConfirm: () => deleteShopifyDistances(id),
        onCancel: () => console.log("Cancelado"),
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo.");
    }
  };

  const columnsShopifyDistances = [
    {
      title: "Municipio",
      dataIndex: "municipality",
      key: "municipality",
    },
    {
      title: "Distancia",
      dataIndex: "distance",
      key: "distance",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space size="middle">
          <ShopifyDistancesDetail record={record} refreshData={fetchData} />
          <DeleteIcon
            TooltipDelete="Eliminar una distancia de Shopify "
            onClick={() => handleDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  const fetchData = async (params = {}) => {
    console.log("Fetching data with params:", JSON.stringify(params));
    try {
      setLoading(true);

      const finalParams = {
        limit: values.limit,
        page: values.page,
        ...params,
      };

      await getShopifyDistances(finalParams);
      setLoading(false);
    } catch (error) {
      message.error("Error al cargar los datos");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, newFilters = {}, sorter = {}) => {
    const { current = 1, pageSize = values.limit } = newPagination;

    const updatedValues = {
      page: current - 1,
      limit: pageSize,
    };
    setValues(updatedValues);

    const cleanParams = Formatter.cleanParamsFromTableToAPI({
      ...newFilters,
      limit: updatedValues.limit,
      page: updatedValues.page,
      dates: [],
    });

    setFilters(newFilters);
    fetchData(cleanParams);
  };

  useEffect(() => {
    fetchData({
      limit: values.limit,
      page: values.page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          total: totalCounShopifyDistances,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            const updatedValues = {
              page: current - 1,
              limit: size,
            };
            setValues(updatedValues);
            fetchData({
              page: updatedValues.page,
              limit: updatedValues.limit,
            });
          },
        }}
        data={shopifyDistances}
        columns={columnsShopifyDistances}
        loading={loading}
      />
    </div>
  );
};

export default ShopifyDistancesBoard;
