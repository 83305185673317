import {
  getCostCentersRepository,
  updateCostCenterRepository,
  postCostCentersRepository,
  deleteCostCentersRepository,
} from "../Infrastructure/CostCenters.repository";

export async function getCostCentersLogic(params) {
  return getCostCentersRepository(params);
}

export async function postCostCentersLogic(params) {
  return postCostCentersRepository(params);
}

export async function deleteCostCentersLogic(params) {
  return deleteCostCentersRepository(params);
}

export async function updateCostCenterLogic(id, params) {
  return updateCostCenterRepository(id, params);
}
