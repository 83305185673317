import React from "react";
import { useState } from "react";
import { Form, Input, message } from "antd";
import "./RepositoryProjects.css";
import { updateRepositoryProject } from "../Infrastructure/RepositoryProjects.service";
import { getRepositoryProjects } from "../Application/RepositoryProjects.business";
import Modal from "../../Modal/Modal";
import { EditIcon } from "../../Utils/UI/Text";
import { EditOutlined } from "@ant-design/icons";
import CustomError from "../../Utils/Domain/CustomError";

const { TextArea } = Input;

const RepositoryProjectDetail = ({ record }) => {
  const [name, setName] = useState(record.name);
  const [form] = Form.useForm();

  const onHandleChange = (event) => {
    setName(event.target.value);
  };

  const handleUpdate = async () => {
    try {
      console.log("Update repository");
      await updateRepositoryProject(record.id, { name });
      message.success("Se modificaron los datos correctamente");
      form.resetFields([name]);
      getRepositoryProjects();
      return true;
    } catch (error) {
      console.log("Error completo:", error);

      const customError = new CustomError(error);
      console.log("Mensaje de error procesado:", customError.message);

      message.error(customError.message);
      return false;
    }
  };
  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
      children={
        <Form
          layout="vertical"
          responsive={"true"}
          form={form}
          onReset={onReset}
        >
          {" "}
          {record ? (
            <>
              <Form.Item label="Nombre:">
                <TextArea
                  allowClear
                  cols="1"
                  rows="3"
                  maxLength={80}
                  onChange={onHandleChange}
                  value={name}
                />
                <div
                  style={{
                    textAlign: "right",
                    fontSize: "12px",
                    color: "#888",
                  }}
                >
                  {name.length}/80 caracteres
                </div>
              </Form.Item>
            </>
          ) : (
            ""
          )}
        </Form>
      }
      icon={<EditIcon TooltipEdit="Editar un Proyecto" />}
      style={{ color: "red" }}
      title="Editar un Proyecto"
      resetFields={""}
      form={form}
      cancelButtonProps={{ style: { display: "none" } }}
      okText="Cerrar"
      handleSubmit={handleUpdate}
      PlusCircleOutlined={<EditOutlined />}
      text="Actualizar"
    />
  );
};

export default RepositoryProjectDetail;
