import {
  saveListCostCenters,
  saveCounCostCenters,
  deleteSelectedCostCenters,
} from "../Infrastructure/CostCenters.store";
import {
  getCostCentersLogic,
  postCostCentersLogic,
  updateCostCenterLogic,
  deleteCostCentersLogic,
} from "./CostCenters.logic";

export async function getCostCenters(params) {
  const { count, resources: costCenters } = await getCostCentersLogic(params);
  saveListCostCenters(costCenters);
  saveCounCostCenters(count);
}

export async function postCostCenters(params) {
  const data = await postCostCentersLogic(params);
}

export async function deleteCostCenters(id) {
  await deleteCostCentersLogic(id);
  deleteSelectedCostCenters(id);
}

export async function updateCostCenter(id, params) {
  await updateCostCenterLogic(id, params);
}
