import { useState, useEffect } from "react";
import InterfaceTable from "../../Table/InterfaceTable";
import { message, Space } from "antd";
import Formatter from "../../Data/Domain/Formatter";
import CostCentersDetail from "./CostCentersDetail";
import { DeleteIcon, ModalDeleteConfirm } from "../../Utils/UI/Text";
import { deleteCostCenters } from "../Application/CostCenters.business";

const CostCentersBoard = ({
  costCenters,
  getCostCenters,
  totalCountCostCenters,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [filters, setFilters] = useState({});
  useEffect(() => {
    fetchData({ page: values.page, limit: values.limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCountCostCenters]);
  const columnsCostCenters = [
    {
      title: "Código Centro de Costo ",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Área	",
      dataIndex: "area",
      key: "area",
    },
    {
      title: "Proyecto",
      dataIndex: "project",
      key: "project",
    },
    {
      title: "Acciones",
      dataIndex: "actions",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <CostCentersDetail record={record} refreshData={fetchData} />
          <DeleteIcon
            TooltipDelete="Eliminar un centro de costo "
            onClick={() => handleDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  const fetchData = async (params = {}) => {
    console.log("Fetching data with params:", JSON.stringify(params));
    try {
      setLoading(true);

      const finalParams = {
        limit: values.limit,
        page: values.page,
        ...params,
      };

      await getCostCenters(finalParams);
      setLoading(false);
    } catch (error) {
      message.error("Error al cargar los datos");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, newFilters = {}, sorter = {}) => {
    const { current = 1, pageSize = values.limit } = newPagination;

    const updatedValues = {
      page: current - 1,
      limit: pageSize,
    };
    setValues(updatedValues);

    const cleanParams = Formatter.cleanParamsFromTableToAPI({
      ...newFilters,
      limit: updatedValues.limit,
      page: updatedValues.page,
      dates: [],
    });

    setFilters(newFilters);
    fetchData(cleanParams);
  };

  useEffect(() => {
    fetchData({
      limit: values.limit,
      page: values.page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleDelete = async (id) => {
    try {
      ModalDeleteConfirm({
        title: "¿Está seguro de eliminar este centro de costo?",
        messageOnOk: "Se eliminó el centro de costo correctamente.",
        onConfirm: () => deleteCostCenters(id),
        onCancel: () => console.log("Cancelado"),
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo.");
    }
  };

  return (
    <>
      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          total: totalCountCostCenters,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            const updatedValues = {
              page: current - 1,
              limit: size,
            };
            setValues(updatedValues);
            fetchData({
              page: updatedValues.page,
              limit: updatedValues.limit,
            });
          },
        }}
        data={costCenters}
        columns={columnsCostCenters}
        loading={loading}
      />
    </>
  );
};

export default CostCentersBoard;
