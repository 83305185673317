import { useState, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import { Row, message } from "antd";
import { Title } from "../../Utils/UI/Text";
import Formatter from "../../Data/Domain/Formatter";
import moment from "moment";
import InterfaceTable from "../../Table/InterfaceTable";
import Statistics from "../../Statistics/Statistics";
import {
  getCommissionsSelector,
  getCommissionsCountSelector,
} from "../Infrastructure/Commissions.reducer";
import { getCommissions } from "../Application/Commissions.bussines";
import RangePicker from "../../Layout/DesignSystem/Picker/Picker";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";

const Commissions = () => {
  const commissions = useSelector(getCommissionsSelector);
  const totalCountCommissions = useSelector(getCommissionsCountSelector);
  const dates = useRef([]);
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    page: 0,
    limit: 10,
  });
  const [filters, setFilters] = useState({});
  useEffect(() => {
    getCommissions();
  }, []);
  useEffect(() => {
    fetchData({ page: values.page, limit: values.limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCountCommissions]);

  const onChange = (changed_values) => {
    dates.current = changed_values
      ? changed_values.map((value) => moment(value).format("YYYY-MM-DD"))
      : [];
    handleRequest({}, {}, {});
  };

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const finalParams = {
        limit: values.limit,
        page: values.page,
        ...params,
      };
      await getCommissions(finalParams);
      setLoading(false);
    } catch (error) {
      message.error("Error al cargar los datos");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, newFilters = {}, sorter = {}) => {
    const { current = 1, pageSize = values.limit } = newPagination;
    const updatedValues = {
      page: current - 1,
      limit: pageSize,
    };
    setValues(updatedValues);

    const cleanParams = Formatter.cleanParamsFromTableToAPI({
      ...newFilters,
      limit: updatedValues.limit,
      page: updatedValues.page,
      dates: dates.current,
    });

    setFilters(newFilters);
    fetchData(cleanParams);
  };

  const CommissionsColumns = [
    {
      title: "Tipo",
      dataIndex: "typeValue",
      key: "typeValue",
      filters: [
        { text: "Ofertas", value: `offers` },
        { text: "Bonos de Carbono", value: `bonds` },
      ],
    },
    { title: "Fecha", dataIndex: "date", key: "date" },
    { title: "Comisión USD", dataIndex: "calculateFee", key: "calculateFee" },
  ];

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          marginBottom: 30,
        }}
      >
        <Title>Comisiones</Title>
        <ExportButtonElement type={"fees"} text={"Exportar"} filter={filters} />
      </div>
      <Statistics
        statistics={[
          {
            value: `$${Formatter.formatUsd(commissions.calculateTotal)}`,
            title: "Comisiones Totales",
          },
          {
            value: `$${Formatter.formatUsd(commissions.calculateBonds)}`,
            title: "Comisiones de bonos de carbono",
          },
          {
            value: `$${Formatter.formatUsd(commissions.calculateOffer)}`,
            title: "Comisiones de Ofertas",
          },
          {
            value: `$${Formatter.formatUsd(commissions.calculateOthers)}`,
            title: "Otras comisiones",
          },
        ]}
      />
      <Row style={{ marginBottom: 20, marginTop: 20 }}>
        <RangePicker onChange={onChange} />
      </Row>
      <InterfaceTable
        rowKey="id"
        data={commissions.resources}
        columns={CommissionsColumns}
        onChange={handleRequest}
        pagination={{
          current: values.page + 1,
          pageSize: values.limit,
          total: totalCountCommissions,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          showTotal: (total, range) =>
            `${range[0]}-${range[1]} de ${total} elementos`,
          onShowSizeChange: (current, size) => {
            const updatedValues = { page: 0, limit: size };
            setValues(updatedValues);
            fetchData({ page: updatedValues.page, limit: updatedValues.limit });
          },
        }}
        loading={loading}
      />
    </>
  );
};

export default Commissions;
