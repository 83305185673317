import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Title } from "../../Utils/UI/Text";
import CostCentersBoard from "./CostCentersBoard";
import { getCostCenters } from "../Application/CostCenters.business";
import {
  getCostCentersSelector,
  getCostCentersCountSelector,
} from "../Infrastructure/CostCenters.reducer";
import "./CostCenters.css";
import { Card, Col } from "antd";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";
import NewCostCenters from "./NewCostCenters";

const CostCenters = () => {
  const costCenters = useSelector(getCostCentersSelector);
  const totalCountCostCenters = useSelector(getCostCentersCountSelector);

  useEffect(() => {
    getCostCenters();
  }, []);
  return (
    <section className="cost-centers-container">
      <Card className="cost-centers-card" bordered={false}>
        <header className="cost-centers-header">
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <Title>Centros de Costos</Title>
            <NewCostCenters />
          </div>

          <Col
            span={12}
            style={{
              width: 112,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <ExportButtonElement type={"costCenter"} text={"Exportar"} />
          </Col>
        </header>
        <main>
          <CostCentersBoard
            costCenters={costCenters}
            getCostCenters={getCostCenters}
            totalCountCostCenters={totalCountCostCenters}
          />
        </main>
      </Card>
    </section>
  );
};

export default CostCenters;
