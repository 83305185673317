import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import Formatter from "../../Data/Domain/Formatter";
import { Space, Card, Col, Row, Typography, message } from "antd";
import InterfaceTable from "../../Table/InterfaceTable";
import { getOwnersByProject } from "../Application/Owners.bussines";
import {
  getsetOwnersByProjectSelector,
  getOwnersByProjectCountSelector,
} from "../Infrastructure/Owners.reducer";
// import NewOwners from "./NewOwners";
import "./Owners.css";
import Storage from "../../Data/Domain/Storage";
import { useNavigate } from "react-router-dom";
import ProjectUploadImage from "../../Projects/UI/ProjectUploadImage";
import Modal from "../../Modal/Modal";
import ProjectsTranslations from "../../Projects/UI/ProjectsTranslations";
import { GlobalOutlinedIcon, FileImageOutlinedIcon } from "../../Utils/UI/Text";
import { ButtonGereral } from "../../Utils/UI/Text";

const { Text } = Typography;

const Owners = ({ setPage }) => {
  const ownersByProject = useSelector(getsetOwnersByProjectSelector);
  const ownersByCount = useSelector(getOwnersByProjectCountSelector);
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const navigate = useNavigate();

  const columns = [
    {
      title: "Nombre",
      dataIndex: "project_name",
      key: "project_name",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        const handleClick = ({ setPage, type, record }) => {
          switch (type) {
            case "ipfs":
              navigate(`/projects/${record.id}/ipfs`);
              setPage("ipfs");
              break;
            case "generals":
              navigate(`/projects/${record.id}/generals`);
              setPage("generals");
              break;
            case "images":
              navigate(`/projects/${record.id}/images`);
              setPage("images");
              break;
            case "transactionsProjects":
              navigate(`/projects/${record.id}/transactions`);
              setPage("transactionsProjects");
              break;
            default:
              break;
          }
        };

        return (
          <Space size="middle">
            <ButtonGereral
              text={"IPFS"}
              onClick={() => handleClick({ setPage, type: "ipfs", record })}
            />
            <Modal
              children={<ProjectUploadImage record={record} />}
              icon={
                <FileImageOutlinedIcon
                  TooltipFileImageOutlined={"Imagen del Proyecto"}
                />
              }
              title="Imagen del Proyecto"
            />

            {
              <Modal
                icon={
                  <GlobalOutlinedIcon TooltipGlobalOutlined={"Traducciones"} />
                }
                title="Traducciones"
                width={1000}
                cancelButtonProps={{ style: { display: "none" } }}
                okButtonProps={{ style: { display: "none" } }}
                children={<ProjectsTranslations record={record} />}
              />
            }

            <ButtonGereral
              text={"GENERAL"}
              onClick={() => handleClick({ setPage, type: "generals", record })}
            />

            <ButtonGereral
              text={"IMÁGENES"}
              onClick={() => handleClick({ setPage, type: "images", record })}
            />

            <ButtonGereral
              text={"TRANSACCIONES"}
              onClick={() =>
                handleClick({ setPage, type: "transactionsProjects", record })
              }
            />
          </Space>
        );
      },
    },
  ];
  const [loading, setLoading] = useState(false);

  const [filters, setFilters] = useState("false");

  const fetchData = async (params = {}) => {
    try {
      const id = params.id || new Storage("id").getItem();

      await getOwnersByProject(id);
      const filtros = params.filter ? JSON.stringify(params.filter) : [""];
      setFilters(filtros);
      if (Object.entries(params).length > 0) message.success("Filtrado");
      setLoading(false);
      message.success("Filtrado");
    } catch (error) {
      message.error("error");
    }
  };

  useEffect(() => {
    getOwnersByProject();
    fetchData({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleRequest = (newPagination = {}, filters = {}, sorter = {}) => {
    const { current: page = 1, pageSize: limit = 5 } = newPagination;
    const { field: sortField, order: sortOrder } = sorter;
    const clean = Formatter.cleanParamsFromTableToAPI({
      ...filters,
      limit,
      sortField,
      sortOrder,
      page: page - 1,
      dates: [],
    });
    setValues({
      ...values,
      limit,
      page: page - 1,
    });
    fetchData(clean);
  };

  return (
    <div className="grid-owners">
      <div>
        <Card className="card-owners">
          <Row className="owners-title">
            <Col className="my-projects" span={24}>
              <Text className="text-my-projects">Mis Proyectos</Text>
              {/* <NewOwners /> */}
            </Col>
          </Row>
          <Row>
            <Col span={24}>
              <InterfaceTable
                rowKey="id"
                data={ownersByProject}
                columns={columns}
                onChange={handleRequest}
                pagination={{
                  pageSize: values.limit,
                  total: ownersByCount,
                  onChange: (current) => {
                    //getOwnersByProject({ page: current - 1, values.limit });
                  },
                }}
              />
            </Col>
          </Row>
        </Card>
      </div>
    </div>
  );
};

export default Owners;
