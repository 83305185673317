import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { message, Tooltip, Input, Space } from "antd";
import InterfaceTable from "../../Table/InterfaceTable";
import moment from "moment";
import { getLogsCountSelector } from "../Infrastructure/Logs.reducer";
import Modal from "../../Modal/Modal";
import DataLog from "./DataLog";
import { AnchorLink, EyeOutlinedIcon } from "../../Utils/UI/Text";
import HashCreation from "./HashCreation";
import URL from "../../Data/Domain/URL";
const { Search } = Input;

export default function LogsBoard({ logs, getLog, getLogs }) {
  const totalCount = useSelector(getLogsCountSelector);
  const [loading, setLoading] = useState(false);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });
  const [searchValue, setSearchValue] = useState("");
  const [debouncedValue, setDebouncedValue] = useState("");

  const fetchData = async () => {
    setLoading(true);
    try {
      const requestParams = {
        page: pagination.current - 1,
        limit: pagination.pageSize,
        filter: debouncedValue ? { q: debouncedValue } : {},
      };

      console.log("Fetching logs with params:", requestParams);
      await getLogs(requestParams);
    } catch (error) {
      message.error("Error fetching logs");
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (newPagination) => {
    setPagination(newPagination);
    fetchData({
      page: newPagination.current - 1,
      limit: newPagination.pageSize,
    });
  };

  const handleUIDClick = (uid) => {
    setSearchValue(uid);
    setDebouncedValue(uid);
    setPagination((prev) => ({ ...prev, current: 1 }));
  };

  const columns = [
    {
      title: "Fecha de Creación",
      dataIndex: "datetime",
      key: "datetime",
      render: (datetime) => moment(datetime).format("YYYY-MM-DD HH:mm:ss"),
    },
    { title: "Descripción", dataIndex: "description", key: "description" },
    { title: "Tipo", dataIndex: "type", key: "type" },
    {
      title: "UID",
      dataIndex: "uid",
      key: "uid",
      render: (uid) => (
        <Tooltip title="Hacer clic para buscar">
          <span
            style={{ cursor: "pointer" }}
            onClick={() => handleUIDClick(uid)}
          >
            {uid}
          </span>
        </Tooltip>
      ),
    },
    {
      title: "Hash",
      dataIndex: "hash",
      key: "hash",
    },
    {
      title: "Blockchain Hash",
      dataIndex: "hash_blockchain",
      key: "hash_blockchain",
      render: (hash_blockchain) =>
        hash_blockchain ? (
          <AnchorLink
            text={hash_blockchain}
            href={`${URL.STELLAR_URL}/transactions/${hash_blockchain}`}
            title="Ver transacción en Stellar"
          />
        ) : (
          "-"
        ),
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) => {
        return (
          <Space size="middle">
            <Modal
              children={<DataLog />}
              icon={
                <EyeOutlinedIcon
                  onClick={() => getLog(record.id)}
                  TooltipEyeOutlined="Ver datos"
                />
              }
              title="Datos del log"
              okText="Cerrar"
              cancelButtonProps={{ style: { display: "none" } }}
            />
            <HashCreation record={record} fetchData={fetchData} />
          </Space>
        );
      },
    },
  ];
  useEffect(() => {
    const timer = setTimeout(() => {
      setDebouncedValue(searchValue);
    }, 300);

    return () => clearTimeout(timer);
  }, [searchValue]);

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [debouncedValue, pagination]);

  return (
    <>
      <div className="search-logs">
        <div>
          <Search
            placeholder="Buscar por UID"
            value={searchValue}
            onChange={(e) => setSearchValue(e.target.value)}
            className="search-logs-item"
            allowClear
          />
        </div>
        <div></div>
      </div>

      <InterfaceTable
        rowKey="_id"
        pagination={{
          current: pagination.current,
          pageSize: pagination.pageSize,
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
        }}
        onChange={handleTableChange}
        data={logs}
        columns={columns}
        loading={loading}
      />
    </>
  );
}
