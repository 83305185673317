import React from "react";
import "./Utils.css";
import { Input, Form, Select, DatePicker } from "antd";

import {
  PlusOutlined,
  EditOutlined,
  DeleteOutlined,
  ApiOutlined,
  EyeOutlined,
  EnvironmentOutlined,
  FileImageOutlined,
  PlusSquareOutlined,
  GlobalOutlined,
  ProfileOutlined,
  LinkOutlined,
  FileWordOutlined,
  ProjectOutlined,
  ContactsOutlined,
  ExclamationCircleOutlined,
  InfoCircleOutlined,
  CalendarOutlined,
  LockOutlined,
} from "@ant-design/icons";

import { Modal as ModalAntd, message, Tooltip, Button } from "antd";
const { TextArea } = Input;

export function Title({ children }) {
  return <span className="title">{children}</span>;
}

export function Subtitle({ children }) {
  return <span className="subtitle">{children}</span>;
}

export function TextBody({ children }) {
  return <p className="textbody">{children}</p>;
}

export function Tag({ children }) {
  return <span className="tag">{children}</span>;
}

export function AddIcon({ onClick }) {
  return <PlusOutlined className="add-icon" onClick={onClick} />;
}
export function EditIcon({ TooltipEdit, onClick }) {
  return (
    <Tooltip title={TooltipEdit}>
      <EditOutlined className="icon-edit" onClick={onClick} />
    </Tooltip>
  );
}
export function DeleteIcon({ onClick, TooltipDelete }) {
  return (
    <Tooltip title={TooltipDelete}>
      <Button
        type="text"
        icon={<DeleteOutlined className="icon-delete" />}
        style={{ transition: "all 0.3s" }}
        onClick={onClick}
      />
    </Tooltip>
  );
}

export function ModalDeleteConfirm({
  title,
  onConfirm,
  onCancel,
  messageOnOk,
}) {
  ModalAntd.confirm({
    title: <span className="modal-title">{title}</span>,
    icon: <ExclamationCircleOutlined className="modal-icon" />,
    content: (
      <div className="modal-content">
        Esta acción no se puede deshacer. Todos los datos relacionados se
        eliminarán de forma permanente.
      </div>
    ),
    centered: true,
    okText: "Sí, eliminar",
    getContainer: document.getElementById("root"),
    okType: "danger",
    cancelText: "Cancelar",
    style: {
      borderRadius: "var(--modal-border-radius)",
      boxShadow: "var(--modal-box-shadow)",
    },

    okButtonProps: {
      style: {
        backgroundColor: "var(--ok-button-bg)",
        borderColor: "var(--ok-button-border)",
        color: "white",
        transition: "all 0.3s ease-in-out",
        borderRadius: "8px",
        fontWeight: "500",
      },
      onMouseEnter: (e) => {
        e.target.style.backgroundColor = "var(--ok-button-hover-bg)";
        e.target.style.borderColor = "var(--ok-button-hover-border)";
        e.target.style.filter = "brightness(1.2)";
        e.target.style.transform = "scale(1.05)";
        e.target.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.2)";
      },
      onMouseLeave: (e) => {
        e.target.style.backgroundColor = "var(--ok-button-bg)";
        e.target.style.borderColor = "var(--ok-button-border)";
        e.target.style.filter = "brightness(1)";
        e.target.style.transform = "scale(1)";
        e.target.style.boxShadow = "none";
      },
    },

    cancelButtonProps: {
      style: {
        backgroundColor: "var(--cancel-button-bg)",
        borderRadius: "8px",
        transition: "all 0.3s",
        color: "var(--modal-content-color)",
      },
      onMouseEnter: (e) =>
        (e.target.style.backgroundColor = "var(--cancel-button-hover-bg)"),
      onMouseLeave: (e) =>
        (e.target.style.backgroundColor = "var(--cancel-button-bg)"),
    },

    onOk: () => {
      onConfirm();
      message.success(messageOnOk);
    },
    onCancel: () => {
      onCancel();
      message.info("Eliminación cancelada.");
    },
  });
}
export function AssociateIcon({ TooltipAssociate }) {
  return (
    <Tooltip title={TooltipAssociate}>
      <ApiOutlined className="icon-edit" />;
    </Tooltip>
  );
}
export function ModalAssociateConfirm({
  title,
  onConfirm,
  onCancel,
  messageOnOk,
}) {
  ModalAntd.confirm({
    title: <span className="modal-title">{title}</span>,
    icon: <ExclamationCircleOutlined className="modal-icon" />,
    content: (
      <div className="modal-content">
        ¿Estás seguro de que deseas asociar estos datos? Esta acción es
        irreversible y todos los datos quedarán vinculados permanentemente.
      </div>
    ),
    centered: true,
    okText: "Sí, asociar",
    getContainer: document.getElementById("root"),
    okType: "danger",
    cancelText: "Cancelar",
    style: {
      borderRadius: "var(--modal-border-radius)",
      boxShadow: "var(--modal-box-shadow)",
    },

    okButtonProps: {
      style: {
        backgroundColor: "var(--ok-button-bg)",
        borderColor: "var(--ok-button-border)",
        color: "white",
        transition: "all 0.3s ease-in-out",
        borderRadius: "8px",
        fontWeight: "500",
      },
      onMouseEnter: (e) => {
        e.target.style.backgroundColor = "var(--ok-button-hover-bg)";
        e.target.style.borderColor = "var(--ok-button-hover-border)";
        e.target.style.filter = "brightness(1.2)";
        e.target.style.transform = "scale(1.05)";
        e.target.style.boxShadow = "0px 4px 10px rgba(0, 0, 0, 0.2)";
      },
      onMouseLeave: (e) => {
        e.target.style.backgroundColor = "var(--ok-button-bg)";
        e.target.style.borderColor = "var(--ok-button-border)";
        e.target.style.filter = "brightness(1)";
        e.target.style.transform = "scale(1)";
        e.target.style.boxShadow = "none";
      },
    },

    cancelButtonProps: {
      style: {
        backgroundColor: "var(--cancel-button-bg)",
        borderRadius: "8px",
        transition: "all 0.3s",
        color: "var(--modal-content-color)",
      },
      onMouseEnter: (e) =>
        (e.target.style.backgroundColor = "var(--cancel-button-hover-bg)"),
      onMouseLeave: (e) =>
        (e.target.style.backgroundColor = "var(--cancel-button-bg)"),
    },

    onOk: () => {
      onConfirm();
      message.success(messageOnOk);
    },
    onCancel: () => {
      onCancel();
      message.info("Asociación cancelada.");
    },
  });
}
export function EyeOutlinedIcon({ TooltipEyeOutlined, onClick }) {
  return (
    <Tooltip title={TooltipEyeOutlined}>
      <EyeOutlined className="icon-edit" onClick={onClick} />
    </Tooltip>
  );
}
export function LockOutlinedIcon({ TooltipLockOutlined, onClick }) {
  return (
    <Tooltip title={TooltipLockOutlined}>
      <LockOutlined className="icon-edit" onClick={onClick} />
    </Tooltip>
  );
}
export function EnvironmentOutlinedIcon({ TooltipEnvironmentOutlined }) {
  return (
    <Tooltip title={TooltipEnvironmentOutlined}>
      <EnvironmentOutlined className="icon-edit" />
    </Tooltip>
  );
}
export function FileImageOutlinedIcon({ TooltipFileImageOutlined }) {
  return (
    <Tooltip title={TooltipFileImageOutlined}>
      <FileImageOutlined className="icon-edit" />
    </Tooltip>
  );
}
export function PlusSquareOutlinedIcon({ TooltipPlusSquareOutlined }) {
  return (
    <Tooltip title={TooltipPlusSquareOutlined}>
      <PlusSquareOutlined className="icon-edit" />
    </Tooltip>
  );
}
export function GlobalOutlinedIcon({ TooltipGlobalOutlined }) {
  return (
    <Tooltip title={TooltipGlobalOutlined}>
      <GlobalOutlined className="icon-edit" />
    </Tooltip>
  );
}
export function ProfileOutlinedIcon({ TooltipProfileOutlined }) {
  return (
    <Tooltip title={TooltipProfileOutlined}>
      <ProfileOutlined className="icon-edit" />
    </Tooltip>
  );
}
export function LinkOutlinedIcon({ TooltipLinkOutlined }) {
  return (
    <Tooltip title={TooltipLinkOutlined}>
      <LinkOutlined className="icon-edit" />
    </Tooltip>
  );
}
export function FileWordOutlinedIcon({ TooltipFileWordOutlined }) {
  return (
    <Tooltip title={TooltipFileWordOutlined}>
      <FileWordOutlined className="icon-edit" />
    </Tooltip>
  );
}
export function ProjectOutlinedIcon({ TooltipProjectOutlined, onClick }) {
  return (
    <Tooltip title={TooltipProjectOutlined}>
      <ProjectOutlined className="icon-edit" onClick={onClick} />
    </Tooltip>
  );
}
export function ContactsOutlinedIcon({ TooltipContactsOutlined }) {
  return (
    <Tooltip title={TooltipContactsOutlined}>
      <ContactsOutlined className="icon-edit" />
    </Tooltip>
  );
}

export function ButtonGereral({
  text,
  onClick,
  TooltipButtonGereral,
  href,
  target,
}) {
  return (
    <Tooltip title={TooltipButtonGereral}>
      <Button
        variant="outlined"
        className="button-general"
        href={href}
        type="button"
        style={{ transition: "all 0.3s" }}
        onClick={onClick}
        target={target}
      >
        {text}
      </Button>
    </Tooltip>
  );
}

export function CustomFormItem({
  label,
  name,
  placeholder,
  maxLength = 100,
  required = true,
  showCounter = false,
  tooltipText = "",
  customRules = [],
  validateFunc,
  dynamicMessage,
  iconSuffix,
  validateStatus,
  help,
}) {
  const validator = async (_, inputValue) => {
    if (validateFunc && !validateFunc(inputValue)) {
      return Promise.reject(new Error(dynamicMessage || "Valor inválido"));
    }
    return Promise.resolve();
  };

  return (
    <Form.Item
      style={{ width: "100%", marginBottom: 16 }}
      label={
        <>
          {label}
          {tooltipText && (
            <Tooltip title={tooltipText}>
              <InfoCircleOutlined style={{ marginLeft: 8, color: "#888" }} />
            </Tooltip>
          )}
        </>
      }
      name={name}
      rules={[
        { required, message: `Por favor ingrese su ${label.toLowerCase()}` },
        {
          max: maxLength,
          message: `Máximo ${maxLength} caracteres permitidos`,
        },
        ...(validateFunc ? [{ validator }] : []),
        ...customRules,
      ]}
      validateStatus={validateStatus}
      help={help}
    >
      <Input
        name={name}
        placeholder={placeholder}
        maxLength={maxLength}
        allowClear
        suffix={
          <>
            {showCounter && (
              <span
                className={`input-counter ${
                  name?.length >= maxLength ? "counter-limit" : ""
                }`}
              >
                {name?.length || 0}/{maxLength}
              </span>
            )}
            {iconSuffix && <span className="input-icon">{iconSuffix}</span>}
          </>
        }
      />
    </Form.Item>
  );
}

export function CustomFormSelect({
  label,
  name,
  placeholder,
  options,
  rules = [],
  allowClear = true,
  disabled = false,
  style,
  formItemStyle,
  tooltipText,
  onChange,
}) {
  return (
    <Form.Item
      label={
        label ? (
          <div style={{ display: "flex", alignItems: "center", gap: 4 }}>
            <span>{label}</span>
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <InfoCircleOutlined
                  style={{ color: "#1890ff", cursor: "pointer" }}
                />
              </Tooltip>
            )}
          </div>
        ) : null
      }
      name={name}
      rules={rules}
      hasFeedback
      style={{ width: "100%", ...formItemStyle }}
    >
      <Select
        placeholder={placeholder}
        options={options}
        allowClear={allowClear}
        disabled={disabled}
        style={{ width: "100%", ...style }}
        onChange={onChange}
      />
    </Form.Item>
  );
}

export function CustomTextArea({
  label,
  tooltipText,
  value,
  onChange,
  placeholder,
  maxLength = 200,
  rows = 4,
  disabled = false,
  allowClear = true,
  showCount = true,
  style,
  labelStyle,
}) {
  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        gap: 4,
      }}
    >
      {label && (
        <div
          style={{
            display: "flex",
            alignItems: "center",
            gap: 4,
            ...labelStyle,
          }}
        >
          <span>{label}</span>
          {tooltipText && (
            <Tooltip title={tooltipText}>
              <InfoCircleOutlined
                style={{ color: "#1890ff", cursor: "pointer" }}
              />
            </Tooltip>
          )}
        </div>
      )}

      <TextArea
        style={{
          width: "100%",
          borderRadius: 8,
          borderColor: "#d9d9d9",
          transition: "border-color 0.3s, box-shadow 0.3s",
          ...style,
        }}
        rows={rows}
        placeholder={placeholder}
        maxLength={maxLength}
        value={value}
        onChange={onChange}
        disabled={disabled}
        allowClear={allowClear}
        showCount={showCount}
        onFocus={(e) => (e.target.style.borderColor = "#1890ff")}
        onBlur={(e) => (e.target.style.borderColor = "#d9d9d9")}
      />
    </div>
  );
}
export function CustomTFormItenextArea({
  label,
  name,
  tooltipText,
  placeholder,
  maxLength,
  rows,
  disabled = false,
  allowClear = true,
  showCount = true,
  required = true,
  customRules = [],
  style,
  labelStyle,
}) {
  return (
    <Form.Item
      style={{ width: "100%", marginBottom: 16 }}
      label={
        label && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              ...labelStyle,
            }}
          >
            <span>{label}</span>
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <InfoCircleOutlined
                  style={{ color: "#1890ff", cursor: "pointer" }}
                />
              </Tooltip>
            )}
          </div>
        )
      }
      name={name}
      rules={[
        { required, message: `Por favor ingrese su ${label?.toLowerCase()}` },
        {
          max: maxLength,
          message: `Máximo ${maxLength} caracteres permitidos`,
        },
        ...customRules,
      ]}
    >
      <TextArea
        style={{
          width: "100%",
          borderRadius: 8,
          borderColor: "#d9d9d9",
          transition: "border-color 0.3s, box-shadow 0.3s",
          ...style,
        }}
        rows={rows}
        maxLength={maxLength}
        placeholder={placeholder}
        disabled={disabled}
        allowClear={allowClear}
        showCount={showCount}
        onFocus={(e) => (e.target.style.borderColor = "#1890ff")}
        onBlur={(e) => (e.target.style.borderColor = "#d9d9d9")}
      />
    </Form.Item>
  );
}

export function CustomDatePicker({
  label,
  name,
  placeholder = "Selecciona una fecha",
  required = true,
  disabled = false,
  tooltipText = "",
  format = "YYYY-MM-DD",
  customRules = [],
  allowClear = true,
  iconSuffix = <CalendarOutlined />,
  style,
  labelStyle,
}) {
  return (
    <Form.Item
      style={{ width: "100%", marginBottom: 16 }}
      label={
        label && (
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
              ...labelStyle,
            }}
          >
            <span>{label}</span>
            {tooltipText && (
              <Tooltip title={tooltipText}>
                <InfoCircleOutlined
                  style={{ color: "#1890ff", cursor: "pointer" }}
                />
              </Tooltip>
            )}
          </div>
        )
      }
      name={name}
      rules={[
        { required, message: `Por favor seleccione ${label?.toLowerCase()}` },
        ...customRules,
      ]}
    >
      <DatePicker
        style={{
          width: "100%",
          borderRadius: 8,
          borderColor: "#d9d9d9",
          transition: "border-color 0.3s, box-shadow 0.3s",
          ...style,
        }}
        placeholder={placeholder}
        format={format}
        disabled={disabled}
        allowClear={allowClear}
        suffixIcon={iconSuffix}
        onFocus={(e) => (e.target.style.borderColor = "#1890ff")}
        onBlur={(e) => (e.target.style.borderColor = "#d9d9d9")}
      />
    </Form.Item>
  );
}
export function AnchorLink({
  text,
  href,
  title,
  color = "#1677ff",
  fontSize = "14px",
  fontWeight = "500",
}) {
  return (
    <Tooltip title={title} mouseEnterDelay={0.3} mouseLeaveDelay={0.1}>
      <a
        href={href}
        target="_blank"
        rel="noopener noreferrer"
        style={{
          color,
          textDecoration: "none",
          fontSize,
          fontWeight,
          transition: "color 0.2s ease-in-out",
        }}
        onMouseEnter={(e) =>
          (e.currentTarget.style.textDecoration = "underline")
        }
        onMouseLeave={(e) => (e.currentTarget.style.textDecoration = "none")}
      >
        {text}
      </a>
    </Tooltip>
  );
}
