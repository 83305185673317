import React from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { Form, Input, message, Modal } from "antd";
import { DeleteOutlined } from "@ant-design/icons";
import "./NewProject.css";
import Button from "../../Layout/DesignSystem/Button/Button";
import {
  postContactProjects,
  removeContact,
} from "../Infrastructure/Projects.service";
import InterfaceTable from "../../Table/InterfaceTable";
import { getProjects } from "../Application/Projects.business";

const NewContact = ({ form, record }) => {
  const [values, setValues] = useState({
    name: "",
    email: "",
    position: "",
    phone: "",
  });
  const dispatch = useDispatch();

  // useEffect(() => {
  //   getProjects();
  // }, []);

  const onHandleChange = (input) => (e) => {
    const value = e.target.value;
    setValues({
      ...values,
      [input]: value,
    });
  };

  async function handleSubmit() {
    const { name, email, position, phone } = values;
    try {
      await postContactProjects(record.id, {
        name,
        email,
        position,
        phone,
      });
      setValues({
        name: "",
        email: "",
        position: "",
        phone: "",
        visible: false,
      });
      message.success("Se agregó un nuevo contacto");
    } catch (error) {
      message.error(" Se produjo un error, vuelve a intentarlo");
    }
    dispatch(getProjects());
  }

  const onReset = () => {
    form.resetFields();
  };
  const handleDelete = async (id, email) => {
    try {
      Modal.confirm({
        title: "Está seguro de que desea eliminar un contacto?",
        okText: "Si",
        okType: "danger",
        onOk: () => {
          removeContact(id, email);
          message.success("Se eliminó el contacto");
        },
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
    {
      title: "Posición",
      dataIndex: "position",
      key: "position",
    },
    {
      title: "Teléfono",
      dataIndex: "phone",
      key: "phone",
    },

    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (record) => {
        return (
          <>
            <DeleteOutlined
              onClick={() => {
                handleDelete(record.id, record.email);
              }}
              style={{ color: "red" }}
            />
          </>
        );
      },
    },
  ];

  return (
    <div>
      <Form layout="inline" form={form} onReset={onReset}>
        {record ? (
          <>
            <div className="item-form">
              <label>Nombre:</label>
              <Form.Item
                name="name"
                rules={[
                  {
                    required: true,
                    message: "Nombre no válido",
                    pattern: new RegExp(
                      /^[a-zA-Z@~`!@#$%^&*()_=+\\\\';:"\\/?>.<,-, ]+$/i
                    ),
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="name"
                  onChange={onHandleChange("name")}
                  value={values.name}
                  maxLength={25}
                  schema={{ default: "", type: "string" }}
                />
              </Form.Item>
            </div>
            <div className="item-form">
              <label>Correo electrónico:</label>
              <Form.Item
                name="email"
                rules={[
                  {
                    type: "email",
                    message: "Email no válido",
                  },
                  {
                    required: true,
                    message: "Ingresa un valor",
                  },
                ]}
                hasFeedback
              >
                <Input
                  name="email"
                  onChange={onHandleChange("email")}
                  value={values.email}
                  maxLength={50}
                  schema={{ default: "", type: "string" }}
                />
              </Form.Item>
            </div>
            <div className="item-form">
              <label>Posición:</label>
              <Form.Item
                name="position"
                rules={[
                  {
                    required: true,
                    message: "Posición no válida",
                    pattern: new RegExp(
                      /^[a-zA-Z0-9@~`!@#$%^&*()_=+\\';:"\/?>.<,-, ]+$/i
                    ),
                  },
                ]}
                hasFeedback
              >
                <Input
                  onChange={onHandleChange("position")}
                  value={values.position}
                />
              </Form.Item>
            </div>
            <div className="item-form">
              <label>Teléfono:</label>
              <Form.Item
                name="phone"
                rules={[
                  {
                    required: true,
                    message: "Teléfono no válido",
                    pattern: new RegExp(/^[0-9]+$/),
                  },
                ]}
                hasFeedback
              >
                <Input
                  onChange={onHandleChange("phone")}
                  value={values.phone}
                />
              </Form.Item>
            </div>

            <div className="item-form">
              <label style={{ color: "white" }}>:</label>

              <Button onClick={handleSubmit} text={"Agregar Contacto"} />
            </div>
          </>
        ) : (
          ""
        )}
      </Form>
      <div>
        <InterfaceTable rowKey="id" data={record?.contacts} columns={columns} />
      </div>
    </div>
  );
};

export default NewContact;
