class CustomError {
  constructor(error_data) {
    this.error_data = error_data;
    if (this.error_data?.response?.data) {
      this.error_data = this.error_data.response.data;
    } 
    else if (this.error_data?.response?.request?.responseText) {
      try {
        this.error_data = JSON.parse(this.error_data.response.request.responseText);
      } catch (e) {
        console.error("Error al parsear responseText:", e);
      }
    }
  }

  get message() {
    switch (true) {
      case this.error_data?.errors && typeof this.error_data.errors === "string":
        return this.error_data.errors;
      case this.error_data?.message && typeof this.error_data.message === "string":
        return this.error_data.message;
      default:
        return "Error inesperado";
    }
  }
}

export default CustomError;
