import Response from "../../Data/Domain/Response";
import CostCenters from "../Domain/CostCenters";
import {
  getCostCenters,
  editCostCenter,
  postCostCenters,
  deleteCostCenters,
} from "./CostCenters.service";

export async function getCostCentersRepository(params) {
  const data = await getCostCenters(params);
  return new Response(data, CostCenters).getMultiple();
}

export async function postCostCentersRepository(params) {
  const data = await postCostCenters(params);
  return new Response(data, CostCenters).getSingle();
}

export async function deleteCostCentersRepository(id) {
  const data = await deleteCostCenters(id);
  return new Response(data).onlyStatus();
}

export async function updateCostCenterRepository(id, params) {
  const data = await editCostCenter(id, params);
  return new Response(data, CostCenters).onlyStatus();
}
