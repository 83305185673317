import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "transportTypes",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setTransportTypes: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },

    countTransportTypes: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },

    deleteTransportType: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },
  },
});

export const { setTransportTypes, countTransportTypes, deleteTransportType } =
  slice.actions;

export default slice.reducer;

export const getTransportTypesSelector = (store) => store.transportTypes.list;
export const getTransportTypesCountSelector = (store) =>
  store.transportTypes.count;
export const deleteTransportTypeSelector = (store) => store.transportTypes.id;
