import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import InterfaceTable from "../../Table/InterfaceTable";
import { Row, Col, message, Form, Modal, Space, Button } from "antd";
import ScriptOffsetsDetail from "./ScriptOffsetsDetail";
import {
  getScriptOffsetSelector,
  getScriptOffsetCountSelector,
} from "../Infrastructure/ScriptOffsets.reducer";
import moment from "moment";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";

import { getScriptOffsetByUser } from "../Application/ScriptOffsets.business";
import SeeOriginScriptOffsets from "./SeeOriginScriptOffsets";
import { EyeOutlinedIcon } from "../../Utils/UI/Text";
const ScriptOffsetsBoard = ({ userId, startDate, finishDate, searchQuery }) => {
  const scriptOffsets = useSelector(getScriptOffsetSelector);
  console.log(JSON.stringify(scriptOffsets));
  const totalCount = useSelector(getScriptOffsetCountSelector);

  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });

  const [loading, setLoading] = useState(false);
  const [isManual, setIsManual] = useState(null);
  const [scopeFilter, setScopeFilter] = useState([]);
  const [form] = Form.useForm();
  const [isSeeOriginModalVisible, setIsSeeOriginModalVisible] = useState(false);
  const [selectedSeeOrigin, setSelectedSeeOrigin] = useState(null);
  const [filters, setFilters] = useState({});

  const handleSeeOrigin = (record) => {
    setSelectedSeeOrigin(record);
    setIsSeeOriginModalVisible(true);
  };

  const handleCopyJSON = () => {
    if (selectedSeeOrigin && selectedSeeOrigin.origin) {
      let originObject;

      if (typeof selectedSeeOrigin.origin === "string") {
        try {
          originObject = JSON.parse(selectedSeeOrigin.origin);
        } catch (error) {
          message.error("Se copió JSON con formato inválido");
          return;
        }
      } else {
        originObject = selectedSeeOrigin.origin;
      }

      const formatJSON = (obj) => {
        const jsonString = JSON.stringify(obj, null, 2);
        return jsonString.replace(/"([^"]+)":/g, "$1:");
      };

      const originJSON = formatJSON(originObject);

      navigator.clipboard
        .writeText(originJSON)
        .then(() => {
          message.success("JSON copiado ");
        })
        .catch((err) => {
          message.error("Error al copiar JSON");
        });
    }
  };
  const columnsScript = [
    {
      title: "Clasificación manual",
      dataIndex: "is_manual",
      key: "is_manual",
      render: (is_manual) => (is_manual ? "SI" : "NO"),
      filters: [
        { text: "SI", value: true },
        { text: "NO", value: false },
      ],
      onFilter: (value, record) => record.is_manual === value,
    },
    {
      title: "Número de Documento",
      dataIndex: "documentNumber",
      key: "documentNumber",
    },
    {
      title: "Área",
      dataIndex: "area",
      key: "area",
      render: (area) => (area.length > 0 ? area.join(", ") : "Sin área"),
    },
    {
      title: "Proyecto",
      dataIndex: "project",
      key: "project",
      render: (project) =>
        project.length > 0 ? project.join(", ") : "Sin proyecto",
    },
    {
      title: "ID del Proveedor",
      dataIndex: "supplierId",
      key: "supplierId",
    },
    {
      title: "Nombre de cuenta",
      dataIndex: "accountName",
      key: "accountName",
    },
    {
      title: "Consumo",
      dataIndex: "consumption",
      key: "consumption",
    },
    {
      title: "Monto total",
      dataIndex: "amountTotal",
      key: "amountTotal",
    },
    {
      title: "Scope",
      dataIndex: "scope",
      key: "scope",
      filters: [
        { text: "Scope 1", value: "scope 1" },
        { text: "Scope 2", value: "scope 2" },
        { text: "Scope 3", value: "scope 3" },
      ],
      onFilter: (value, record) => record.scope === value,
      render: (scope) => scope || "-",
    },
    {
      title: "Categoría",
      dataIndex: "subScope",
      key: "subScope",
    },
    {
      title: "Subcategoría",
      dataIndex: "subCategory",
      key: "subCategory",
    },
    {
      title: "Factor",
      dataIndex: "factor",
      key: "factor",
    },
    {
      title: "KG Totales CO₂",
      dataIndex: "total_co2_kg",
      key: "total_co2_kg",
    },
    {
      title: "Ton. Totales de CO₂",
      dataIndex: "total_co2_tons",
      key: "total_co2_tons",
    },
    {
      title: "Fecha",
      dataIndex: "accountingDate",
      key: "accountingDate",
      render: (accountingDate) => {
        const date = accountingDate
          ? moment(accountingDate).format("YYYY-MM-DD")
          : "Fecha no disponible";
        return date;
      },
    },

    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => {
        return (
          <Space size="middle">
            <ScriptOffsetsDetail
              record={record}
              form={form}
              userId={userId}
              Offid={record.id || record._id}
              refreshData={() =>
                fetchData({
                  page: values.page,
                  limit: values.limit,
                  ...filters,
                })
              }
            />
            <EyeOutlinedIcon
              TooltipEyeOutlined="Ver Origen"
              onClick={() => handleSeeOrigin(record)}
            />
          </Space>
        );
      },
    },
  ];

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);

      const apiFilters = {
        ...params,
        start_date: params.start_date || undefined,
        finish_date: params.finish_date || undefined,
        q: params.q || undefined,
        user: userId,
      };

      if (params.is_manual !== undefined) {
        apiFilters.is_manual = params.is_manual;
      }

      const apiParams = {
        filter: apiFilters,
        page: params.page !== undefined ? params.page : values.page,
        limit: params.limit !== undefined ? params.limit : values.limit,
        field_sort: params.field_sort,
        sort: params.sort,
      };

      const response = await getScriptOffsetByUser(userId, apiParams);
      console.log("Respuesta recibida:", response.data);

      setLoading(false);
    } catch (error) {
      console.error("Error completo:", error);
      setLoading(false);
      message.error("Error al cargar los datos");
    }
  };
  const handleRequest = (
    newPagination = {},
    filtersFromTable = {},
    sorter = {}
  ) => {
    const { current: page = 1, pageSize: limit = values.limit } = newPagination;
    const field_sort = !searchQuery ? sorter.field : null;
    const sort = !searchQuery
      ? sorter.order === "ascend"
        ? "ascending"
        : "descending"
      : null;

    const activeFilters = {
      ...filters,
      ...filtersFromTable,
      start_date: startDate,
      finish_date: finishDate,
      q: searchQuery,
      user: userId,
    };

    setIsManual(
      activeFilters.is_manual !== undefined ? activeFilters.is_manual : null
    );
    setScopeFilter(activeFilters.scope || []);
    setFilters(activeFilters);

    setValues({
      ...values,
      limit,
      page: page - 1,
    });

    fetchData({
      ...activeFilters,
      limit,
      field_sort,
      sort,
      page: page - 1,
    });
  };
  useEffect(() => {
    if (userId) {
      fetchData({
        page: values.page,
        limit: values.limit,
        ...filters,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filters, userId]);

  useEffect(() => {
    if (userId) {
      fetchData({
        page: values.page,
        limit: values.limit,
        ...filters,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeFilter, isManual, userId]);

  useEffect(() => {
    if (userId) {
      setValues((prev) => ({ ...prev, page: 0 }));
      fetchData({
        page: 0,
        limit: values.limit,
        start_date: startDate,
        finish_date: finishDate,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, finishDate, userId]);

  useEffect(() => {
    if (userId) {
      setValues((prev) => ({ ...prev, page: 0 }));
      fetchData({
        page: 0,
        limit: values.limit,
        q: searchQuery,
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [searchQuery, userId]);
  return (
    <div>
      <Row style={{ marginBottom: 20 }}>
        <Col span={12}>
          <h2>Script Offsets</h2>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <ExportButtonElement
            type={"scriptOffsets"}
            text={"Exportar"}
            filter={{
              user: userId,
              start_date: startDate,
              finish_date: finishDate,
              is_manual: isManual,
              scope: scopeFilter,
              q: searchQuery,
            }}
          />
        </Col>
      </Row>

      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onChange: (page, pageSize) => {
            const newValues = {
              page: page - 1,
              limit: pageSize,
            };
            setValues(newValues);
            fetchData({
              ...filters,
              ...newValues,
            });
          },
          onShowSizeChange: (current, size) => {
            const newValues = {
              page: 0,
              limit: size,
            };
            setValues(newValues);
            fetchData({
              ...filters,
              ...newValues,
            });
          },
        }}
        data={scriptOffsets}
        columns={columnsScript}
        loading={loading}
      />
      {selectedSeeOrigin && (
        <Modal
          width="auto"
          centered
          footer={
            <Button type="primary" onClick={handleCopyJSON}>
              Copiar
            </Button>
          }
          closable={true}
          visible={isSeeOriginModalVisible}
          onCancel={() => setIsSeeOriginModalVisible(false)}
          title="Origen"
        >
          <SeeOriginScriptOffsets scriptOffsets={selectedSeeOrigin.origin} />
        </Modal>
      )}
    </div>
  );
};

export default ScriptOffsetsBoard;
