import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Layout, Row, Col } from "antd";
import { Title } from "../../Utils/UI/Text";
import { getNfts } from "../Application/Nfts.business";
import NftsBoard from "./NftsBoard";
import {
  getNftsSelector,
  getNftsCountSelector,
} from "../Infrastructure/Nfts.reducer";
const { Content } = Layout;

export default function Nfts() {
  const nftsData = useSelector(getNftsSelector);
  console.log(JSON.stringify(nftsData));
  const totalCount = useSelector(getNftsCountSelector);

  useEffect(() => {
    getNfts();
  }, []);

  return (
    <Content style={{ padding: "20px" }}>
      <Row style={{ marginBottom: 16 }} justify="space-between" align="middle">
        <Col>
          <Title level={3} style={{ margin: 0 }}>
            NFTs
          </Title>
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <NftsBoard
            nftsData={nftsData}
            getNfts={getNfts}
            totalCount={totalCount}
          />
        </Col>
      </Row>
    </Content>
  );
}
