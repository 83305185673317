import { useEffect } from "react";
import { useSelector } from "react-redux";
import InterfaceTable from "../../Table/InterfaceTable";
import { Space, message } from "antd";
import { DeleteIcon, ModalDeleteConfirm } from "../../Utils/UI/Text";
import SuppliersDetails from "./SuppliersDetail";
import SuppliersAssociation from "./SuppliersAssociation";
import { getSuppliersSelector } from "../Infrastructure/Suppliers.reducer";
import {
  getSuppliers,
  deleteSupplier,
} from "../Application/Suppliers.business";
const SuppliersBoard = () => {
  const repositoryProjects = useSelector(getSuppliersSelector);

  useEffect(() => {
    getSuppliers();
  }, []);

  const handleDelete = async (id) => {
    try {
      console.log(id);

      ModalDeleteConfirm({
        title: "¿Está seguro de eliminar un proyecto",
        messageOnOk: "Se eliminó el proyecto",
        onConfirm: () => deleteSupplier(id),
        onCancel: () => console.log("Cancelado"),
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo");
    }
  };

  const columns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",

      render: (record) => {
        return (
          <Space size="middle">
            <SuppliersDetails record={record} />

            <SuppliersAssociation record={record} />

            <DeleteIcon
              TooltipDelete="Eliminar un Proyecto "
              onClick={() => handleDelete(record.id)}
            />
          </Space>
        );
      },
    },
  ];
  return (
    <InterfaceTable rowKey="id" data={repositoryProjects} columns={columns} />
  );
};

export default SuppliersBoard;
