import { useState } from "react";
import { Form, message } from "antd";
import { PlusCircleOutlined, InfoCircleOutlined } from "@ant-design/icons";
import "./Styles.css";
import ModalInterface from "../../Modal/Modal";
import Storage from "../../Data/Domain/Storage";
import { getSuppliers } from "../Application/Suppliers.business";
import { postSuppliers } from "../Infrastructure/Suppliers.service";
import { AddIcon, CustomFormItem } from "../../Utils/UI/Text";
import CustomError from "../../Utils/Domain/CustomError";

const separatorStyle = {
  marginLeft: "4px",
};

const NewSuppliers = () => {
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    name: "",
    company: new Storage("id").getItem(),
  });
  const [formErrors, setFormErrors] = useState({});

  async function handleSubmit() {
    const { name } = values;
    try {
      await postSuppliers({
        name,
      });
      setValues({ ...values, visible: false });
      message.success("Se agregó un nuevo Proveedor");
      getSuppliers(values.company);
      return true;
    } catch (error) {
      console.log("Error completo:", error);

      const customError = new CustomError(error);
      console.log("Mensaje de error procesado:", customError.message);

      message.error(customError.message);

      if (customError.message.includes("")) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          name: customError.message,
        }));
      }
      return false;
    }
  }

  const onReset = () => {
    form.resetFields();
  };

  return (
    <div>
      <ModalInterface
        children={
          <div className="form-container">
            <Form
              layout="vertical"
              responsive={"true"}
              form={form}
              onFinish={handleSubmit}
              onReset={onReset}
              onValuesChange={(changedValues, allValues) => {
                const updatedValues = { ...allValues };

                if (changedValues.name) {
                  updatedValues.name = changedValues.name.replace(
                    /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                    ""
                  );
                }

                setValues(updatedValues);
                form.setFieldsValue(updatedValues);
              }}
              style={{ width: "100%" }}
            >
              <CustomFormItem
                label="Nombre"
                name={"name"}
                placeholder="Ingrese el nombre del proveedor"
                maxLength={80}
                showCounter
                tooltipText="Solo se permiten letras y espacios. Máximo 80 caracteres."
                rules={[
                  {
                    required: true,
                    message: "Nombre no válido",
                    pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                  },
                ]}
                iconSuffix={<InfoCircleOutlined />}
                validateStatus={formErrors.name ? "error" : ""}
                help={formErrors.name}
              />
            </Form>
          </div>
        }
        icon={<AddIcon />}
        style={separatorStyle}
        title="Nombre del Proveedor"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        handleSubmit={handleSubmit}
        text="Agregar"
        form={form}
        PlusCircleOutlined={<PlusCircleOutlined />}
      />
    </div>
  );
};

export default NewSuppliers;
