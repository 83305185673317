import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Title } from "../../Utils/UI/Text";
import EquivalentCodesBoard from "./EquivalentCodesBoard";
import { getEquivalentCodes } from "../Application/EquivalentCodes.business";
import {
  getEquivalentCodesSelector,
  getEquivalentCodesCountSelector,
} from "../Infrastructure/EquivalentCodes.reducer";
import "./EquivalentCodes.css";
import { Card, Col } from "antd";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";
import NewEquivalentCodes from "./NewEquivalentCodes";

const EquivalentCodes = () => {
  const equivalentCodes = useSelector(getEquivalentCodesSelector);
  const totalCountEquivalentCodes = useSelector(
    getEquivalentCodesCountSelector
  );

  useEffect(() => {
    getEquivalentCodes();
  }, []);
  return (
    <section className="equivalent-codes-container">
      <Card className="equivalent-codes-card" bordered={false}>
        <header className="equivalent-codes-container">
          <div className="equivalent-codes-header">
            <Title> Códigos equivalentes</Title>
            <div className="shopify-distances-title">
              <NewEquivalentCodes getEquivalentCodes={getEquivalentCodes} />{" "}
            </div>
          </div>
          <div>
            <ExportButtonElement type={"equivalentCodes"} text={"Exportar"} />
          </div>
        </header>
        <main>
          <EquivalentCodesBoard
            equivalentCodes={equivalentCodes}
            getEquivalentCodes={getEquivalentCodes}
            totalCountEquivalentCodes={totalCountEquivalentCodes}
          />
        </main>
      </Card>
    </section>
  );
};

export default EquivalentCodes;
