import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getEquivalentCodes(params) {
  return await new API(
    URL.setParams(URL.buildUrl("equivalentCodes"), params)
  ).get();
}
export async function putEquivalentCodes(id, data) {
  const url = URL.setId(URL.buildUrl("equivalentCodes"), id);
  return await new API(url).put(data);
}

export async function deleteEquivalenceCode(id) {
  return await new API(URL.setId(URL.buildUrl("equivalentCodes"), id)).delete();
}

export async function postEquivalentCodes({ code, equivalence, year }) {
  return await new API(URL.buildUrl("equivalentCodes")).post({
    code,
    equivalence,
    year,
  });
}
