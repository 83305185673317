import React, { useState } from "react";
import { message, Form } from "antd";
import { getCostCenters } from "../Application/CostCenters.business";
import { postCostCenters } from "../Application/CostCenters.business";
import Modal from "../../Modal/Modal";
import CustomError from "../../Utils/Domain/CustomError";
import { CustomFormItem, AddIcon } from "../../Utils/UI/Text";
import {
  CodeOutlined,
  BorderOutlined,
  EnvironmentOutlined,
  PlusCircleOutlined,
} from "@ant-design/icons";
const NewCostCenters = () => {
  const [form] = Form.useForm();

  const [values, setValues] = useState({
    code: "",
    area: "",
    project: "",
  });

  const [formErrors, setFormErrors] = useState({});
  async function handleSubmit() {
    const { code, area, project } = values;

    try {
      await postCostCenters({
        code,
        area,
        project,
      });
      setValues({ ...values, visible: false });

      message.success("Se agregó un nuevo costo");
      setValues({ code: "", area: "", project: "" });
      form.resetFields();
      getCostCenters();
      return true;
    } catch (error) {
      console.log("Error completo:", error);

      const customError = new CustomError(error);
      console.log("Mensaje de error procesado:", customError.message);

      message.error(customError.message);

      if (customError.message.includes("Email already registered")) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: customError.message,
        }));
      }
      return false;
    }
  }

  return (
    <div>
      <Modal
        children={
          <div>
            <Form
              responsive={"true"}
              layout="vertical"
              onFinish={handleSubmit}
              form={form}
              initialValues={values}
              onValuesChange={(changedValues, allValues) => {
                const updatedValues = { ...allValues };
                setValues(updatedValues);
                form.setFieldsValue(updatedValues);
              }}
            >
              <CustomFormItem
                label="Código"
                name="code"
                placeholder="Ingrese un código"
                iconSuffix={<CodeOutlined style={{ color: "#52c41a" }} />}
                validateStatus={formErrors.code ? "error" : ""}
                help={formErrors.code}
              />

              <CustomFormItem
                label="Área"
                name="area"
                placeholder="Ingrese el área"
                iconSuffix={<BorderOutlined style={{ color: "#52c41a" }} />}
                validateStatus={formErrors.area ? "error" : ""}
                help={formErrors.area}
              />

              <CustomFormItem
                label="Proyecto"
                name="project"
                placeholder="Ingrese un proyecto"
                iconSuffix={
                  <EnvironmentOutlined style={{ color: "#52c41a" }} />
                }
                validateStatus={formErrors.project ? "error" : ""}
                help={formErrors.project}
              />
            </Form>
          </div>
        }
        icon={<AddIcon />}
        style={{
          marginLeft: "12px",
        }}
        title="Agregar Centro de Costo"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
        PlusCircleOutlined={<PlusCircleOutlined />}
      />
    </div>
  );
};

export default NewCostCenters;
