import {  useState } from "react";
import { Form, Input,  message } from "antd";
import { updateArea } from "../Application/Areas.business";
import { getAreas } from "../Application/Areas.business";
import { EditIcon } from "../../Utils/UI/Text";
import { EditOutlined } from "@ant-design/icons";
import Modal from "../../Modal/Modal";
import CustomError from "../../Utils/Domain/CustomError";


export default function AreasEdit({  record }) {
  const [loading, setLoading] = useState(false);
  const [nameArea, setNameArea] = useState(record.name);
  const [form] = Form.useForm();


  const onHandleChange = (input) => (e) => {
    setNameArea(e.target.value);
  };
  const handleUpdate = async () => {
    try {
      setLoading(true);
      const data = {
        name: nameArea,
      };
        await updateArea(record.id, data)
        message.success("Area actualizada con éxito");
      form.resetFields([data]);
      getAreas();
      return true;
    } catch (error) {
      console.log("Error completo:", error);

      const customError = new CustomError(error);
      console.log("Mensaje de error procesado:", customError.message);

      message.error(customError.message);
      return false;
    }
  };


  const onReset = () => {
    form.resetFields();
  };

  return (
    <Modal
    children={  <Form
      name="basic"
      labelCol={{
        span: 10,
      }}
      wrapperCol={{
        span: 16,
      }}
      initialValues={{
        nameArea: nameArea,
        remember: false,
      }}
      autoComplete="off"
      form={form}
      onReset={onReset}
    >
      {" "}
      {record ? (
        <>
         

<Form.Item 
  label="Nombre" 
  style={{ marginBottom: 16, width: "100%" }} 
  labelCol={{ span: 24 }}
  wrapperCol={{ span: 24 }} 
>
  <div style={{ width: "100%" }}> 
    <Input.TextArea
      name="nameArea"
      value={nameArea}
      maxLength={80}
      onChange={onHandleChange(nameArea)}
      style={{ width: "100%" }}
      allowClear
       cols="1" rows="3"
    />
    
    <div style={{ textAlign: "right", fontSize: "12px", color: "#888" }}>
      {nameArea.length}/80 caracteres
    </div>
  </div>
</Form.Item>


        </>
      ) : (
        ""
      )}
    </Form>}
   
    icon={<EditIcon TooltipEdit="Editar un área" />}

    style={{ color: "red" }}
    title="Editar un área"
    resetFields={"nameArea"}
    form={form}
    cancelButtonProps={{ style: { display: "none" } }}
    okText="Cerrar"
    handleSubmit={handleUpdate}
    PlusCircleOutlined={<EditOutlined />}
    text="Editar"
    
    
  />
    
  );
}
