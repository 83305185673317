import {
  getTransportTypesRepository,
  updateTransportTypeRepository,
  deleteTransportTypeRepository,
} from "../Infrastructure/TransportTypes.repository";

export async function getTransportTypesLogic(params) {
  return getTransportTypesRepository(params);
}

export async function updateTransportTypeLogic(id, params) {
  return updateTransportTypeRepository(id, params);
}

export async function deleteTransportTypeLogic(id) {
  return deleteTransportTypeRepository(id);
}
