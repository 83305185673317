import React, { useEffect, useState } from "react";
import { Form, InputNumber, message } from "antd";
import Searcher from "../../ManageData/UI/SearcherManageData";
import { postReduction } from "../../Saving/Application/Saving.business";
import { AssociateIcon } from "../../Utils/UI/Text";
import { ModalAssociateConfirm } from "../../Utils/UI/Text";
import { ApiOutlined } from "@ant-design/icons";
import Modal from "../../Modal/Modal";

const styles = {
  container: {
    width: "100%",
  },
  input: {
    width: "100%",
    minWidth: 400,
  },
};
export default function AreasAssociation({ record }) {
  const [loading, setLoading] = useState(false);
  const [nameArea, setNameArea] = useState(record.name);
  const [id, setId] = useState(null);
  const [tons, setTons] = useState(null);
  const [enabledButton, setEnabledButton] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setEnabledButton(id && tons);
  }, [id, tons]);

  const onHandleChange = () => (value) => {
    setTons(value);
  };

  const handleUpdate = async (id) => {
    try {
      ModalAssociateConfirm({
        title: "¿Está seguro de asociar esta área??",
        messageOnOk: "Área asociada correctamente.",
        onConfirm: async () =>
          await postReduction({
            parent: record.id,
            quantity: tons,
            type: "area",
            id_user: id,
            up: true,
            percent: 100,
          }),
        onCancel: () => console.log("Cancelado"),
      });
    } catch (error) {
      message.error("Se produjo un error, vuelva a intentarlo.");
    }
  };
  const onReset = () => {
    form.resetFields();
  };
  return (
    <Modal
      width="540px"
      children={
        <Form
          name="basic"
          labelCol={{
            span: 4,
          }}
          wrapperCol={{
            span: 20,
          }}
          initialValues={{
            nameArea: nameArea,
            remember: false,
          }}
          autoComplete="off"
          form={form}
          onReset={onReset}
        >
          {record ? (
            <>
              <div className="manageData">
                <div className="grid-Association">
                  <div>
                    <Searcher
                      userId={id}
                      setId={(id) => {
                        setId(id);
                      }}
                    />
                  </div>
                </div>
              </div>

              <Form.Item style={styles.container} label={"Toneladas"}>
                <Form.Item>
                  <InputNumber
                    style={styles.input}
                    placeholder="tons"
                    name={"tons"}
                    min={1}
                    value={tons}
                    onChange={onHandleChange("tons")}
                  />
                </Form.Item>
              </Form.Item>
            </>
          ) : (
            ""
          )}
        </Form>
      }
      icon={<AssociateIcon TooltipAssociate="Asociar un área" />}
      disabled={!enabledButton}
      title="Asociar un área"
      form={form}
      cancelButtonProps={{ style: { display: "none" } }}
      okText="Cerrar"
      handleSubmit={handleUpdate}
      PlusCircleOutlined={<ApiOutlined />}
      text="Asociar"
    />
  );
}
