import React, { useState, useEffect, useCallback } from "react";
import { Space, Row, Col, message, Modal, DatePicker, Input } from "antd";
import InterfaceTable from "../../Table/InterfaceTable";
import CarbonFluxTable from "./CarbonFluxTable";
import { ButtonGereral } from "../../Utils/UI/Text";

const { RangePicker } = DatePicker;
const { Search } = Input;

const NftsBoard = ({ totalCount, nftsData, getNfts }) => {
  const [values, setValues] = useState({ page: 0, limit: 5 });
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [typeFilter, setTypeFilter] = useState(null);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedNftId, setSelectedNftId] = useState(null);

  const fetchData = useCallback(
    async (params = {}) => {
      try {
        const filters = {
          start_date: dateRange[0]?.format("YYYY-MM-DD"),
          finish_date: dateRange[1]?.format("YYYY-MM-DD"),
          ...params,
        };

        setLoading(true);
        const requestParams = {
          filter: filters,
          page: params.page ?? values.page,
          limit: params.limit ?? values.limit,
          type: params.type ?? typeFilter,
        };
        await getNfts(requestParams);
      } catch (error) {
        message.error("Error al cargar los datos");
      } finally {
        setLoading(false);
      }
    },
    [dateRange, values.page, values.limit, typeFilter, getNfts]
  );

  const handleRequest = useCallback(
    (newPagination = {}, newFilters = {}) => {
      const { current = 1, pageSize = values.limit } = newPagination;
      const typeFilterValues =
        newFilters.type?.length > 0 ? newFilters.type : null;

      const updatedValues = {
        page: typeFilterValues !== typeFilter ? 0 : current - 1,
        limit: pageSize,
      };

      setValues((prev) => ({ ...prev, ...updatedValues }));
      setTypeFilter(typeFilterValues);

      fetchData({ ...updatedValues, type: typeFilterValues });
    },
    [values.limit, typeFilter, fetchData]
  );

  const onChange = useCallback(
    (changed_values) => {
      const value = changed_values.target
        ? changed_values.target.value
        : changed_values;
      setValues((prev) => ({ ...prev, page: 0 }));
      fetchData({ q: value, page: 0 });
    },
    [fetchData]
  );

  const handleModalOpen = useCallback((record) => {
    setSelectedNftId(record.id);
    setIsModalVisible(true);
  }, []);

  const handleModalClose = useCallback(() => {
    setIsModalVisible(false);
  }, []);

  const handleDateChange = useCallback((dates) => {
    setDateRange(dates || []);
  }, []);

  useEffect(() => {
    fetchData({ page: values.page, limit: values.limit, type: typeFilter });
  }, [values.page, values.limit, typeFilter, fetchData]);

  const columns = [
    { title: "Code", dataIndex: "assetCode", key: "assetCode" },
    {
      title: "Hash de Blockchain",
      dataIndex: "blockchainHash",
      key: "blockchainHash",
    },
    { title: "Hash de IPFS", dataIndex: "ipfsHash", key: "ipfsHash" },
    {
      title: "NFT/SBT",
      dataIndex: "isSbt",
      key: "type",
      filters: [
        { text: "NFT", value: "is_nft" },
        { text: "SBT", value: "is_sbt" },
      ],
      render: (text) => text,
      filteredValue: typeFilter,
    },
    { title: "Proyecto", dataIndex: "projectName", key: "projectName" },
    { title: "Usuario", dataIndex: "username", key: "username" },
    {
      title: "Fecha de compra",
      dataIndex: "boughtAt",
      key: "boughtAt",
      render: (boughtAt) => (boughtAt ? boughtAt.slice(0, 10) : "N/A"),
    },
    { title: "Fecha de Offset", dataIndex: "usedAt", key: "usedAt" },
    { title: "Usado por", dataIndex: "usedBy", key: "usedBy" },
    {
      title: "Periodo de Offset",
      dataIndex: "offsetPeriod",
      key: "offsetPeriod",
    },
    {
      title: "Acciones",
      key: "actions",
      render: (_, record) => (
        <Space size="middle">
          <ButtonGereral
            text="Ver CarbonFlux"
            onClick={() => handleModalOpen(record)}
          />
          {record.isSbt === "SBT" && (
            <ButtonGereral
              href={record.certificate}
              target="_blank"
              text="Ver Certificado"
            />
          )}
        </Space>
      ),
    },
  ];

  return (
    <div className="grid-script" style={{ display: "grid" }}>
      <Row style={{ marginBottom: 20 }}>
        <Col xl={6}>
          <RangePicker
            onChange={handleDateChange}
            placeholder={["Fecha de inicio", "Fecha de fin"]}
          />
        </Col>
        <Col xl={12} style={{ width: "100%" }}>
          <Search
            placeholder="Buscar"
            onSearch={onChange}
            onChange={onChange}
            width="100%"
            className="search-item"
          />
        </Col>
      </Row>

      <InterfaceTable
        rowKey="id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          total: totalCount,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (_, size) => {
            setValues((prev) => ({ ...prev, limit: size, page: 0 }));
          },
        }}
        data={nftsData}
        columns={columns}
        loading={loading}
      />
      <Modal
        title="Información de CarbonFluxes"
        visible={isModalVisible}
        onCancel={handleModalClose}
        footer={null}
        width="95%"
      >
        <CarbonFluxTable id={selectedNftId} />
      </Modal>
    </div>
  );
};

export default NftsBoard;
