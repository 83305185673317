import { useEffect } from "react";
import { useSelector } from "react-redux";
import { Title } from "../../Utils/UI/Text";
import ShopifyDistancesBoard from "./ShopifyDistancesBoard";
import { getShopifyDistances } from "../Application/ShopifyDistances.business";
import {
  getShopifyDistancesSelector,
  getShopifyDistancesCountSelector,
} from "../Infrastructure/ShopifyDistances.reducer";
import NewShopifyDistances from "./NewShopifyDistances";
import "./ShopifyDistances.css";
import { Card, Col, Layout, Row } from "antd";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";

const ShopifyDistances = () => {
  const shopifyDistances = useSelector(getShopifyDistancesSelector);
  const totalCounShopifyDistances = useSelector(
    getShopifyDistancesCountSelector
  );

  useEffect(() => {
    getShopifyDistances();
  }, []);
  return (
    <section className="shopify-distances-container">
      <Card className="shopify-distances-card" bordered={false}>
        <header className="shopify-distances-header">
          <Col span={6}>
            <Title>Distancias de Shopify</Title>
          </Col>
          <Col span={6}>
            <div className="shopify-distances-title">
              <NewShopifyDistances />{" "}
            </div>
          </Col>
          <Col
            span={12}
            style={{
              width: 112,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <ExportButtonElement
              type={"shopifyDistances"}
              text={"Exportar"}
              // filter={}
            />
          </Col>
        </header>
        <main>
          <ShopifyDistancesBoard
            shopifyDistances={shopifyDistances}
            getShopifyDistances={getShopifyDistances}
            totalCounShopifyDistances={totalCounShopifyDistances}
          />
        </main>
      </Card>
    </section>
  );
};

export default ShopifyDistances;
