import store from "../../Redux/store";
import {  setCostCenters,
  countCostCenters, deleteCostCenters } from "./CostCenters.reducer";

export function saveListCostCenters(records) {
  store.dispatch(setCostCenters(records));
}

export function saveCounCostCenters(count) {
  store.dispatch(countCostCenters(count));
}

export function deleteSelectedCostCenters(id) {
  store.dispatch(deleteCostCenters(id));
}

