import Response from "../../Data/Domain/Response";
import EquivalentCodes from "../Domain/EquivalentCodes";
import { getEquivalentCodes,putEquivalentCodes, deleteEquivalenceCode } from "./EquivalentCodes.service";

export async function getEquivalentCodesRepository(params) {
  const data = await getEquivalentCodes(params);
  return new Response(data, EquivalentCodes).getMultiple();
}
export async function updateEquivalentCodesRepository(id, params) {
  const data = await putEquivalentCodes(id, params);
  return new Response(data, EquivalentCodes).onlyStatus();
}

export async function deleteEquivalenceCodesRepository(id) {
  const data = await deleteEquivalenceCode(id);
  return new Response(data).onlyStatus();
}