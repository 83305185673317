import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Table, DatePicker, Row, Col, message } from "antd";
import { getCarbonFlux } from "../Application/CarbonFlux.business";
import {
  getCarbonFluxSelector,
  getCarbonFluxCountSelector,
} from "../Infrastructure/CarbonFlux.reducer";

const { RangePicker } = DatePicker;

export default function CarbonFluxBoard() {
  const carbonFluxData = useSelector(getCarbonFluxSelector);
  const totalCount = useSelector(getCarbonFluxCountSelector);
  const [loading, setLoading] = useState(false);
  const [dateRange, setDateRange] = useState([]);
  const [processedFilter, setProcessedFilter] = useState([]);
  const [pagination, setPagination] = useState({
    current: 1,
    pageSize: 10,
  });

  const fetchData = async () => {
    setLoading(true);

    try {
      const filter = {
        start_date: dateRange[0]
            ? dateRange[0].format("YYYY-MM-DD")
            : undefined,
        finish_date: dateRange[1]
            ? dateRange[1].format("YYYY-MM-DD")
            : undefined,
        processed: processedFilter.length > 0 ? processedFilter : undefined,
      };

      Object.keys(filter).forEach(
          (key) => filter[key] === undefined && delete filter[key]
      );

      const requestParams = {
        filter,
        page: pagination.current - 1,
        limit: pagination.pageSize,
      };

      await getCarbonFlux(requestParams);
    } catch (error) {
      message.error("Error fetching carbon flux data");
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dateRange, processedFilter, pagination]);

  const handleTableChange = (newPagination, filters) => {
    setPagination({
      current: newPagination.current,
      pageSize: newPagination.pageSize,
    });

    const processed = Array.isArray(filters.processed) ? filters.processed : [];
    setProcessedFilter(processed);
  };

  const columns = [
    { title: "Fecha de Registro (DD/MM/AAAA)", dataIndex: "createdAt", key: "createdAt" },
    {
      title: "Procesado",
      dataIndex: "processed",
      key: "processed",
      filters: [
        { text: "Sí", value: true },
        { text: "No", value: false },
      ],
      render: (processed) => (processed ? "Sí" : "No"),
    },
    {
      title: "Constelación",
      dataIndex: "constellationName",
      key: "constellationName",
    },
    { title: "Proyecto", dataIndex: "projectName", key: "projectName" },
    { title: "Estación", dataIndex: "station", key: "station" },
    {
      title: "Dato de la estación",
      dataIndex: "stationData",
      key: "stationData",
    },
    {
      title: "Área Productiva ( m²)",
      dataIndex: "productiveAreaSize",
      key: "productiveAreaSize",
    },
    {
      title: "Contribución Neta (g)",
      dataIndex: "grossContribution",
      key: "grossContribution",
    },
    { title: "Saldo (g)", dataIndex: "remainder", key: "remainder" },
    { title: "Total del Registro (g)", dataIndex: "totalCo2", key: "totalCo2" },
  ];

  return (
      <div>
        <Row style={{ marginBottom: 16 }} justify="space-between" align="middle">
          <Col>
            <RangePicker
                onChange={(dates) => setDateRange(dates || [])}
                placeholder={["Fecha de inicio", "Fecha de fin"]}
            />
          </Col>
        </Row>
        <Table
            dataSource={carbonFluxData}
            columns={columns}
            loading={loading}
            rowKey="id"
            pagination={{
              current: pagination.current,
              pageSize: pagination.pageSize,
              total: totalCount,
              showSizeChanger: true,
              pageSizeOptions: ["5", "10", "20", "50", "100"],
            }}
            onChange={handleTableChange}
        />
      </div>
  );
}
