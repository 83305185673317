import { useState, useEffect } from "react";
import InterfaceTable from "../../Table/InterfaceTable";
import EquivalentCodesDetail from "./EquivalentCodesDetail";
import { message, Space, Modal as ModalAntd } from "antd";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import Formatter from "../../Data/Domain/Formatter";
import { deleteEquivalenceCode } from "../Application/EquivalentCodes.business";
import { DeleteIcon } from "../../Utils/UI/Text";

const EquivalentCodesBoard = ({
  equivalentCodes,
  getEquivalentCodes,
  totalCountEquivalentCodes,
}) => {
  const [loading, setLoading] = useState(false);
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [filters, setFilters] = useState({});

  useEffect(() => {
    fetchData({ page: values.page, limit: values.limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [totalCountEquivalentCodes]);

  const handleDelete = (id) => {
    ModalAntd.confirm({
      title: (
        <span style={{ fontWeight: 600, color: "#1D1D1F" }}>
          ¿Está seguro de que desea eliminar este código equivalente?
        </span>
      ),
      icon: <ExclamationCircleOutlined style={{ color: "#ff4d4f" }} />,
      content: (
        <div style={{ fontSize: "14px", color: "#595959" }}>
          Esta acción no se puede deshacer. Todos los datos relacionados se
          eliminarán de forma permanente.
        </div>
      ),
      centered: true,
      okText: "Sí, eliminar",
      cancelText: "Cancelar",
      okType: "danger",
      getContainer: document.getElementById("root"),
      style: {
        borderRadius: "12px",
        boxShadow: "0px 4px 10px rgba(0, 0, 0, 0.15)",
      },
      okButtonProps: {
        style: {
          backgroundColor: "#d9363e",
          borderColor: "#d9363e",
          color: "white",
          fontWeight: "500",
          borderRadius: "8px",
          transition: "all 0.3s ease-in-out",
        },
        onMouseEnter: (e) => {
          e.target.style.backgroundColor = "#a82222";
          e.target.style.borderColor = "#a82222";
        },
        onMouseLeave: (e) => {
          e.target.style.backgroundColor = "#d9363e";
          e.target.style.borderColor = "#d9363e";
        },
      },
      cancelButtonProps: {
        style: {
          backgroundColor: "#f5f5f5",
          borderRadius: "8px",
          color: "#595959",
        },
        onMouseEnter: (e) => (e.target.style.backgroundColor = "#e6e6e6"),
        onMouseLeave: (e) => (e.target.style.backgroundColor = "#f5f5f5"),
      },

      onOk: async () => {
        try {
          await deleteEquivalenceCode(id);
          message.success(
            "El código equivalente ha sido eliminado correctamente."
          );
          fetchData();
        } catch (error) {
          message.error(
            "Ocurrió un error al eliminar el código equivalente. Inténtelo de nuevo."
          );
        }
      },
      onCancel: () => {
        message.info("Eliminación cancelada.");
      },
    });
  };

  const columnsEquivalentCodes = [
    {
      title: "Código",
      dataIndex: "code",
      key: "code",
    },
    {
      title: "Equivalencia",
      dataIndex: "equivalence",
      key: "equivalence",
    },
    {
      title: "Año",
      dataIndex: "year",
      key: "year",
    },
    {
      title: "Acciones",
      dataIndex: "",
      key: "",
      render: (_, record) => (
        <Space size="middle">
          <EquivalentCodesDetail record={record} refreshData={fetchData} />

          <DeleteIcon
            TooltipDelete="Eliminar un código equivalente "
            onClick={() => handleDelete(record.id)}
          />
        </Space>
      ),
    },
  ];

  const fetchData = async (params = {}) => {
    console.log("Fetching data with params:", JSON.stringify(params));
    try {
      setLoading(true);

      const finalParams = {
        limit: values.limit,
        page: values.page,
        ...params,
      };

      await getEquivalentCodes(finalParams);
      setLoading(false);
    } catch (error) {
      message.error("Error al cargar los datos");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, newFilters = {}, sorter = {}) => {
    const { current = 1, pageSize = values.limit } = newPagination;

    const updatedValues = {
      page: current - 1,
      limit: pageSize,
    };
    setValues(updatedValues);

    const cleanParams = Formatter.cleanParamsFromTableToAPI({
      ...newFilters,
      limit: updatedValues.limit,
      page: updatedValues.page,
      dates: [],
    });

    setFilters(newFilters);
    fetchData(cleanParams);
  };

  useEffect(() => {
    fetchData({
      limit: values.limit,
      page: values.page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div>
      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          total: totalCountEquivalentCodes,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            const updatedValues = {
              page: current - 1,
              limit: size,
            };
            setValues(updatedValues);
            fetchData({
              page: updatedValues.page,
              limit: updatedValues.limit,
            });
          },
        }}
        data={equivalentCodes}
        columns={columnsEquivalentCodes}
        loading={loading}
      />
    </div>
  );
};

export default EquivalentCodesBoard;
