import { useState, useEffect, useRef } from "react";
import { Col, Row, Form, message } from "antd";
import "./NewProject.css";
import ModalInterface from "../../Modal/Modal";
import { postProject } from "../Infrastructure/Projects.service";
import { getProjects } from "../Application/Projects.business";
import {
  PlusCircleOutlined,
  UserOutlined,
  IdcardOutlined,
  GlobalOutlined,
  CloudOutlined,
  DollarCircleOutlined,
  CodeOutlined,
  CalendarOutlined,
  LinkOutlined,
  FileTextOutlined,
  BorderOutlined,
  NodeIndexOutlined,
  ProjectOutlined,
  ContainerOutlined,
  EnvironmentOutlined,
} from "@ant-design/icons";
import {
  AddIcon,
  CustomFormItem,
  CustomFormSelect,
  CustomDatePicker,
} from "../../Utils/UI/Text";
import CustomError from "../../Utils/Domain/CustomError";

const separatorStyle = {
  color: "#08c",
  marginLeft: "12px",
};

const NewProject = () => {
  const [form] = Form.useForm();
  const dates = useRef([]);

  const [values, setValues] = useState({
    name: "",
    is_private: false,
    country: "",
    co2Goal: 0,
    ton_price: "",
    add_on: "",
    technology: "",
    vintage: "",
    url: "",
    url_file: "",
    standard_id: "",
    area: "",
    biomass: "",
    co2_annual_estimated: "",
    contract_start_date: null,
    contract_end_date: null,
    description: "",
    green_project_type: "",
    region: "",
    volume: "",
    ton_co2_issued: "",
    province: "",
  });
  const [showPrivate, setShowPrivate] = useState(false);
  const [showPublic, setShowPublic] = useState(false);

  useEffect(() => {
    setValues(values);
  }, [values]);
  const [formErrors, setFormErrors] = useState({});

  async function handleSubmit() {
    try {
      const {
        name,
        is_private,
        country,
        co2Goal,
        ton_price,
        standard_id,
        add_on,
        technology,
        vintage,
        url,
        url_file,
        area,
        biomass,
        co2_annual_estimated,
        contract_start_date,
        contract_end_date,
        description,
        green_project_type,
        region,
        volume,
        ton_co2_issued,
        province,
      } = values;

      const projectData = is_private
        ? { add_on, technology, vintage, url, url_file }
        : {
            area,
            biomass,
            co2_annual_estimated,
            contract_start_date,
            contract_end_date,
            description,
            green_project_type,
            region,
            volume,
            ton_co2_issued,
            province,
          };

      await postProject({
        name,
        is_private,
        country,
        co2Goal,
        ton_price,
        standard_id,
        ...projectData,
      });

      onReset();
      message.success("Se agregó un nuevo Proyecto");
      getProjects({ page: 0, limit: values.limit });
      return true;
    } catch (error) {
      console.log("Error completo:", error);

      const customError = new CustomError(error);
      console.log("Mensaje de error procesado:", customError.message);

      message.error(customError.message);

      return false;
    }
  }

  const onReset = () => {
    form.resetFields();
    setValues({});
    setShowPrivate(false);
    setShowPublic(false);
  };

  return (
    <div>
      <ModalInterface
        children={
          <div className="form-container">
            <Form
              layout="vertical"
              responsive="true"
              form={form}
              onReset={onReset}
              onFinish={handleSubmit}
              onValuesChange={(changedValues, allValues) => {
                const updatedValues = { ...allValues };

                if (changedValues.name) {
                  updatedValues.name = changedValues.name.replace(
                    /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                    ""
                  );
                }

                if (changedValues.standard_id) {
                  updatedValues.standard_id = changedValues.standard_id.replace(
                    /[^A-Za-z0-9]/g,
                    ""
                  );
                }

                if (changedValues.country) {
                  updatedValues.country = changedValues.country.replace(
                    /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                    ""
                  );
                }

                if (changedValues.co2Goal) {
                  updatedValues.co2Goal = changedValues.co2Goal.replace(
                    /[^0-9]/g,
                    ""
                  );
                }

                if (changedValues.ton_price) {
                  updatedValues.ton_price = changedValues.ton_price.replace(
                    /[^0-9.]/g,
                    ""
                  );
                }

                if (changedValues.add_on) {
                  updatedValues.add_on = changedValues.add_on.replace(
                    /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                    ""
                  );
                }

                if (changedValues.technology) {
                  updatedValues.technology = changedValues.technology.replace(
                    /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                    ""
                  );
                }

                if (changedValues.vintage) {
                  updatedValues.vintage = changedValues.vintage.replace(
                    /[^0-9]/g,
                    ""
                  );
                }

                if (changedValues.url) {
                  updatedValues.url = changedValues.url.replace(
                    /[^A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=]/g,
                    ""
                  );
                }

                if (changedValues.url_file) {
                  updatedValues.url_file = changedValues.url_file.replace(
                    /[^A-Za-z0-9-._~:/?#[\]@!$&'()*+,;=]/g,
                    ""
                  );
                }

                if (changedValues.area) {
                  updatedValues.area = changedValues.area.replace(
                    /[^0-9.]/g,
                    ""
                  );
                }

                if (changedValues.biomass) {
                  updatedValues.biomass = changedValues.biomass.replace(
                    /[^0-9.]/g,
                    ""
                  );
                }

                if (changedValues.co2_annual_estimated) {
                  updatedValues.co2_annual_estimated =
                    changedValues.co2_annual_estimated.replace(/[^0-9.]/g, "");
                }

                if (changedValues.description) {
                  updatedValues.description = changedValues.description.replace(
                    /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s.,]/g,
                    ""
                  );
                }

                if (changedValues.green_project_type) {
                  updatedValues.green_project_type =
                    changedValues.green_project_type.replace(
                      /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                      ""
                    );
                }

                if (changedValues.region) {
                  updatedValues.region = changedValues.region.replace(
                    /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                    ""
                  );
                }

                if (changedValues.volume) {
                  updatedValues.volume = changedValues.volume.replace(
                    /[^0-9.]/g,
                    ""
                  );
                }

                if (changedValues.ton_co2_issued) {
                  updatedValues.ton_co2_issued =
                    changedValues.ton_co2_issued.replace(/[^0-9.]/g, "");
                }

                if (changedValues.province) {
                  updatedValues.province = changedValues.province.replace(
                    /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                    ""
                  );
                }

                setValues(updatedValues);
                form.setFieldsValue(updatedValues);

                if (changedValues.hasOwnProperty("is_private")) {
                  setShowPrivate(changedValues.is_private === true);
                  setShowPublic(changedValues.is_private === false);
                }
              }}
            >
              <Row gutter={8}>
                <Col span={12}>
                  <CustomFormItem
                    label="Nombre"
                    name="name"
                    placeholder="Ingrese su nombre"
                    rules={[
                      {
                        required: true,
                        message: "Nombre no válido",
                        pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                      },
                    ]}
                    iconSuffix={
                      <UserOutlined
                        style={{ color: "#1890ff" }}
                        validateStatus={formErrors.name ? "error" : ""}
                        help={formErrors.name}
                      />
                    }
                  />
                </Col>

                <Col span={12}>
                  <CustomFormItem
                    label="standard_id"
                    name="standard_id"
                    placeholder="Ingrese el ID estándar"
                    rules={[
                      {
                        required: true,
                        message: "ID estándar no válido",
                        pattern: /^[A-Za-z0-9]+$/,
                      },
                    ]}
                    iconSuffix={
                      <IdcardOutlined
                        style={{ color: "#52c41a" }}
                        validateStatus={formErrors.standard_id ? "error" : ""}
                        help={formErrors.standard_id}
                      />
                    }
                  />
                </Col>
              </Row>

              <Row gutter={8}>
                <Col span={12}>
                  <Form.Item
                    label="Tipo"
                    name="is_private"
                    rules={[
                      {
                        required: true,
                        message: "Seleccione si es público o privado",
                      },
                    ]}
                  >
                    <CustomFormSelect
                      value={values.is_private ?? undefined}
                      onChange={(value) => {
                        form.setFieldsValue({ is_private: value });
                        setValues((prev) => ({
                          ...prev,
                          is_private: value,
                        }));

                        if (value === true) {
                          setShowPrivate(true);
                          setShowPublic(false);
                        } else if (value === false) {
                          setShowPrivate(false);
                          setShowPublic(true);
                        } else {
                          setShowPrivate(false);
                          setShowPublic(false);
                        }
                      }}
                      options={[
                        { value: true, label: "Privado" },
                        { value: false, label: "Público" },
                      ]}
                      placeholder="Seleccione un tipo"
                    />
                  </Form.Item>
                </Col>

                <Col span={12}>
                  <CustomFormItem
                    label="País"
                    name="country"
                    placeholder="Ingrese su país"
                    rules={[
                      {
                        required: true,
                        message: "País no válido",
                        pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                      },
                    ]}
                    iconSuffix={<GlobalOutlined style={{ color: "#52c41a" }} />}
                    validateStatus={formErrors.country ? "error" : ""}
                    help={formErrors.country}
                  />
                </Col>
              </Row>
              <Row gutter={8}>
                <Col span={12}>
                  <CustomFormItem
                    label="Meta de CO₂"
                    name="co2Goal"
                    placeholder="Ingrese la meta de CO₂"
                    rules={[
                      {
                        required: true,
                        message: "Meta de CO₂ no válida",
                        pattern: /^[0-9]+$/,
                      },
                    ]}
                    iconSuffix={<CloudOutlined style={{ color: "#52c41a" }} />}
                    validateStatus={formErrors.co2Goal ? "error" : ""}
                    help={formErrors.co2Goal}
                  />
                </Col>

                <Col span={12}>
                  <CustomFormItem
                    label="Precio por tonelada"
                    name="ton_price"
                    placeholder="Ingrese el precio por tonelada"
                    rules={[
                      {
                        required: true,
                        message: "Precio no válido",
                        pattern: /^[0-9.]+$/,
                      },
                    ]}
                    iconSuffix={
                      <DollarCircleOutlined style={{ color: "#faad14" }} />
                    }
                    validateStatus={formErrors.ton_price ? "error" : ""}
                    help={formErrors.ton_price}
                  />
                </Col>
              </Row>

              {showPrivate && (
                <>
                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Adicional"
                        name="add_on"
                        placeholder="Ingrese un adicional"
                        rules={[
                          {
                            required: true,
                            message: "Adicional no válido",
                            pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          },
                        ]}
                        iconSuffix={
                          <PlusCircleOutlined style={{ color: "#1890ff" }} />
                        }
                        validateStatus={formErrors.add_on ? "error" : ""}
                        help={formErrors.add_on}
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Tecnología"
                        name="technology"
                        placeholder="Ingrese una tecnología"
                        rules={[
                          {
                            required: true,
                            message: "Tecnología no válida",
                            pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          },
                        ]}
                        iconSuffix={
                          <CodeOutlined style={{ color: "#52c41a" }} />
                        }
                        validateStatus={formErrors.technology ? "error" : ""}
                        help={formErrors.technology}
                      />
                    </Col>
                  </Row>

                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Vintage"
                        name="vintage"
                        placeholder="Ingrese su vintage"
                        rules={[
                          {
                            required: true,
                            message: "Vintage no válido",
                            pattern: /^[0-9]+$/,
                          },
                        ]}
                        iconSuffix={
                          <CalendarOutlined style={{ color: "#faad14" }} />
                        }
                        validateStatus={formErrors.vintage ? "error" : ""}
                        help={formErrors.vintage}
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="URL"
                        name="url"
                        placeholder="Ingrese una URL"
                        rules={[
                          {
                            type: "url",
                            message: "La URL no es válida",
                          },
                        ]}
                        iconSuffix={
                          <LinkOutlined style={{ color: "#1890ff" }} />
                        }
                        validateStatus={formErrors.url ? "error" : ""}
                        help={formErrors.url}
                      />
                    </Col>
                  </Row>

                  <CustomFormItem
                    label="Archivo de URL"
                    name="url_file"
                    placeholder="Ingrese el archivo de URL"
                    rules={[
                      {
                        type: "url",
                        message: "La URL no es válida",
                      },
                    ]}
                    iconSuffix={
                      <FileTextOutlined style={{ color: "#1890ff" }} />
                    }
                    validateStatus={formErrors.url_file ? "error" : ""}
                    help={formErrors.url_file}
                  />
                </>
              )}
              {showPublic && (
                <>
                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Área"
                        name="area"
                        placeholder="Ingrese el área"
                        rules={[
                          {
                            required: true,
                            message: "Área no válida",
                            pattern: /^[0-9.]+$/,
                          },
                        ]}
                        iconSuffix={
                          <BorderOutlined style={{ color: "#52c41a" }} />
                        }
                        validateStatus={formErrors.area ? "error" : ""}
                        help={formErrors.area}
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Biomasa"
                        name="biomass"
                        placeholder="Ingrese la biomasa"
                        rules={[
                          {
                            required: true,
                            message: "Biomasa no válida",
                            pattern: /^[0-9.]+$/,
                          },
                        ]}
                        iconSuffix={
                          <NodeIndexOutlined style={{ color: "#52c41a" }} />
                        }
                        validateStatus={formErrors.biomass ? "error" : ""}
                        help={formErrors.biomass}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Estimación Anual de CO2"
                        name="co2_annual_estimated"
                        placeholder="Ingrese la estimación anual de CO2"
                        rules={[
                          {
                            required: true,
                            message: "Estimación no válida",
                            pattern: /^[0-9.]+$/,
                          },
                        ]}
                        iconSuffix={
                          <CloudOutlined style={{ color: "#52c41a" }} />
                        }
                        validateStatus={
                          formErrors.co2_annual_estimated ? "error" : ""
                        }
                        help={formErrors.co2_annual_estimated}
                      />
                    </Col>

                    <Col span={12}>
                      <CustomDatePicker
                        label="Fecha inicial del contrato"
                        name="contract_start_date"
                        placeholder="Selecciona la fecha de inicio"
                        iconSuffix={
                          <CalendarOutlined style={{ color: "#52c41a" }} />
                        }
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomDatePicker
                        label="Fecha final del contrato"
                        name="contract_end_date"
                        placeholder="Selecciona la fecha final"
                        iconSuffix={
                          <CalendarOutlined style={{ color: "#ff4d4f" }} />
                        }
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Descripción"
                        name="description"
                        placeholder="Ingrese la descripción"
                        rules={[
                          {
                            required: true,
                            message: "Descripción no válida",
                            pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s.,]+$/,
                          },
                        ]}
                        iconSuffix={
                          <FileTextOutlined style={{ color: "#1890ff" }} />
                        }
                        validateStatus={formErrors.description ? "error" : ""}
                        help={formErrors.description}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Tipo de proyecto"
                        name="green_project_type"
                        placeholder="Ingrese el tipo de proyecto"
                        rules={[
                          {
                            required: true,
                            message: "Tipo de proyecto no válido",
                            pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          },
                        ]}
                        iconSuffix={
                          <ProjectOutlined style={{ color: "#52c41a" }} />
                        }
                        validateStatus={
                          formErrors.green_project_type ? "error" : ""
                        }
                        help={formErrors.green_project_type}
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Región"
                        name="region"
                        placeholder="Ingrese la región"
                        rules={[
                          {
                            required: true,
                            message: "Región no válida",
                            pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          },
                        ]}
                        iconSuffix={
                          <GlobalOutlined style={{ color: "#1890ff" }} />
                        }
                        validateStatus={formErrors.region ? "error" : ""}
                        help={formErrors.region}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Volumen"
                        name="volume"
                        placeholder="Ingrese el volumen"
                        rules={[
                          {
                            required: true,
                            message: "Volumen no válido",
                            pattern: /^[0-9.]+$/,
                          },
                        ]}
                        iconSuffix={
                          <ContainerOutlined style={{ color: "#722ed1" }} />
                        }
                        validateStatus={formErrors.volume ? "error" : ""}
                        help={formErrors.volume}
                      />
                    </Col>

                    <Col span={12}>
                      <CustomFormItem
                        label="Toneladas de CO2 emitidas"
                        name="ton_co2_issued"
                        placeholder="Ingrese las toneladas de CO2 emitidas"
                        rules={[
                          {
                            required: true,
                            message: "Toneladas no válidas",
                            pattern: /^[0-9.]+$/,
                          },
                        ]}
                        iconSuffix={
                          <CloudOutlined style={{ color: "#fa541c" }} />
                        }
                        validateStatus={
                          formErrors.ton_co2_issued ? "error" : ""
                        }
                        help={formErrors.ton_co2_issued}
                      />
                    </Col>
                  </Row>
                  <Row gutter={8}>
                    <Col span={12}>
                      <CustomFormItem
                        label="Provincia"
                        name="province"
                        placeholder="Ingrese la provincia"
                        rules={[
                          {
                            required: true,
                            message: "Provincia no válida",
                            pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                          },
                        ]}
                        iconSuffix={
                          <EnvironmentOutlined style={{ color: "#13c2c2" }} />
                        }
                        validateStatus={formErrors.province ? "error" : ""}
                        help={formErrors.province}
                      />
                    </Col>
                  </Row>
                </>
              )}
            </Form>
          </div>
        }
        icon={<AddIcon />}
        style={separatorStyle}
        title="Nombre del proyecto"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
        PlusCircleOutlined={<PlusCircleOutlined />}
      />
    </div>
  );
};

export default NewProject;
