import {  saveListEquivalentCodes, saveCountEquivalentCodes, deleteSelectedEquivalenceCode } from "../Infrastructure/EquivalentCodes.store";
import { getEquivalentCodesLogic,updateEquivalentCodesLogic, deleteEquivalenceCodeLogic } from "./EquivalentCodes.logic";

export async function getEquivalentCodes(params) {
  const { count, resources: equivalentCodes } = await getEquivalentCodesLogic(params);
  saveListEquivalentCodes(equivalentCodes);
  saveCountEquivalentCodes(count);
}

export async function updateEquivalentCodes(id, params) {
  await updateEquivalentCodesLogic(id, params);
}

export async function deleteEquivalenceCode(id) {
  await deleteEquivalenceCodeLogic(id);
  deleteSelectedEquivalenceCode(id);
}