import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Col, Row, Input, message } from "antd";
import { Title } from "../../Utils/UI/Text";
import Statistics from "../../Statistics/Statistics";
import InterfaceTable from "../../Table/InterfaceTable";
import {
  getUsersSelector,
  getUsersCountSelector,
  getcountWithoutTransactionsSelector,
} from "../../Users/Infrastructure/Users.reducer";
import { getUsers } from "../../Users/Application/Users.business";
import "./CRM.css";
import Formatter from "../../Data/Domain/Formatter";
import ExportButtonElement from "../../Layout/DesignSystem/ExportButton/Button";

const { Search } = Input;

const CRM = () => {
  const crm = useSelector(getUsersSelector);
  console.log(JSON.stringify(crm));
  const countUsers = useSelector(getUsersCountSelector);
  const countWithoutTransactions = useSelector(
    getcountWithoutTransactionsSelector
  );
  useEffect(() => {
    getUsers();
  }, []);
  const [values, setValues] = useState({
    page: 0,
    limit: 5,
  });
  const [loading, setLoading] = useState(false);
  const [filters, setFilters] = useState({});
  useEffect(() => {
    fetchData({ page: values.page, limit: values.limit });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [countUsers]);
  const onChange = (changed_values) => {
    const value = changed_values.target
      ? changed_values.target.value
      : changed_values;
    fetchData({ filter: { q: value } });
  };

  const fetchData = async (params = {}) => {
    console.log("Fetching data with params:", JSON.stringify(params));
    try {
      setLoading(true);

      const finalParams = {
        limit: values.limit,
        page: values.page,
        ...params,
      };

      await getUsers(finalParams);
      setLoading(false);
    } catch (error) {
      message.error("Error al cargar los datos");
      setLoading(false);
    }
  };

  const handleRequest = (newPagination = {}, newFilters = {}, sorter = {}) => {
    const { current = 1, pageSize = values.limit } = newPagination;

    const updatedValues = {
      page: current - 1,
      limit: pageSize,
    };
    setValues(updatedValues);

    const cleanParams = Formatter.cleanParamsFromTableToAPI({
      ...newFilters,
      limit: updatedValues.limit,
      page: updatedValues.page,
      dates: [],
    });

    setFilters(newFilters);
    fetchData(cleanParams);
  };

  useEffect(() => {
    fetchData({
      limit: values.limit,
      page: values.page,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const statistics = [
    {
      value: countUsers,
      title: "Número total de usuarios registrados",
    },
    {
      value: countWithoutTransactions,
      title: "Número total de usuarios sin transacciones",
    },
  ];

  const CrmColumns = [
    {
      title: "Nombre",
      dataIndex: "name",
      key: "name",
    },
    {
      title: "Apellido",
      dataIndex: "lastname",
      key: "lastname",
    },
    {
      title: "Email",
      dataIndex: "email",
      key: "email",
    },
  ];

  const title = {
    marginBottom: 20,
  };

  return (
    <>
      <Row style={title}>
        <Col
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            marginBottom: 20,
          }}
          span={24}
        >
          <Col span={12}>
            <Title>CRM</Title>
          </Col>
          <Col
            span={12}
            style={{
              width: 112,
              display: "flex",
              flexDirection: "column",
              alignItems: "flex-end",
            }}
          >
            <ExportButtonElement
              type={"users"}
              text={"Exportar"}
              filter={filters}
            />
          </Col>
        </Col>
      </Row>

      <Statistics statistics={statistics} />
      <div className="search">
        <Search
          placeholder="Buscar"
          onSearch={onChange}
          onChange={onChange}
          className="search-item"
        />
      </div>

      <InterfaceTable
        rowKey="_id"
        onChange={handleRequest}
        pagination={{
          pageSize: values.limit,
          current: values.page + 1,
          total: countUsers,
          showSizeChanger: true,
          pageSizeOptions: ["5", "10", "20", "50", "100"],
          onShowSizeChange: (current, size) => {
            const updatedValues = {
              page: current - 1,
              limit: size,
            };
            setValues(updatedValues);
            fetchData({
              page: updatedValues.page,
              limit: updatedValues.limit,
            });
          },
        }}
        data={crm}
        columns={CrmColumns}
        loading={loading}
      />
    </>
  );
};

export default CRM;
