import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "costCenters",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setCostCenters: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },
   
    countCostCenters: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },
    deleteCostCenters: (state, { payload: id }) => {
      return {
        ...state,
        list: [...state.list.filter((elem) => elem.id !== id)],
      };
    },

  },
});

export const {
  setCostCenters,
  countCostCenters,
  deleteCostCenters,
} = slice.actions;

export default slice.reducer;

export const getCostCentersSelector = (store) => store.costCenters.list;
export const getCostCentersCountSelector = (store) => store.costCenters.count;
