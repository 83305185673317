import store from "../../Redux/store";
import {  setEquivalentCodes,
  countEquivalentCodes, deleteEquivalenceCodes } from "./EquivalentCodes.reducer";

export function saveListEquivalentCodes(records) {
  store.dispatch(setEquivalentCodes(records));
}

export function saveCountEquivalentCodes(count) {
  store.dispatch(countEquivalentCodes(count));
}

export function deleteSelectedEquivalenceCode(id) {
  store.dispatch(deleteEquivalenceCodes(id));
}
