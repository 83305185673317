import { Typography, Card } from "antd";
import SuppliersBoard from "./SuppliersBoard";
import NewSuppliers from "./NewSuppliers";

const { Text } = Typography;
const styles = {
  text: {
    fontFamily: "MontserratBold",
    fontStyle: "normal",
    fontWeight: 700,
    fontSize: "20px",
    lineHeight: "24px",
    color: "#041370",
  },
};

export default function Suppliers() {
  return (
    <div className="grid-emission">
      <div>
        <Card className="card-content">
          <div
            style={{
              marginBottom: 20,
              display: "flex",
              justifyContent: "flex-start",
              alignItems: "center",
            }}
          >
            <div>
              <Text style={styles.text}>Suppliers</Text>
            </div>
            <div>
              <NewSuppliers />
            </div>
          </div>
          <SuppliersBoard />
        </Card>
      </div>
    </div>
  );
}
