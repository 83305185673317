import API from "../../Data/Domain/API";
import URL from "../../Data/Domain/URL";

export async function getTransportTypes(params) {
  return await new API(
    URL.setParams(URL.buildUrl("transportTypes"), params)
  ).get();
}

export async function editTransportType(id, data) {
  const url = URL.setId(URL.buildUrl("transportTypes"), id);
  return await new API(url).put(data);
}

export async function deleteTransportType(id) {
  return await new API(URL.setId(URL.buildUrl("transportTypes"), id)).delete();
}
