import React, { useEffect } from "react";
import { useParams } from "react-router-dom";
import { Layout, Breadcrumb, Col, Row } from "antd";
import { Title } from "../../Utils/UI/Text";
import NewCompany from "./NewCompany";
import { getCompany, getCompanies } from "../Application/Companies.business";
import Searcher from "./Searcher";
import CompaniesForm from "./Companies.form";

const { Content } = Layout;

export default function Companies() {
  const { id } = useParams();

  useEffect(() => {
    getCompanies();
    if (id) {
      getCompany(id);
    }
  }, [id]);

  return (
    <>
      <Content>
        <Breadcrumb>
          <Breadcrumb.Item>
            <Row>
              <Col
                style={{
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                  marginBottom: 20,
                }}
                span={24}
              >
                <Title>Compañías</Title>
                <NewCompany getCompanies={getCompanies} />
              </Col>
            </Row>
            <Searcher userId={id} />
            <div className="site-layout-content">
              <CompaniesForm userId={id} />
            </div>
          </Breadcrumb.Item>
        </Breadcrumb>
      </Content>
    </>
  );
}
