import { createSlice } from "@reduxjs/toolkit";

export const slice = createSlice({
  name: "equivalentCodes",
  initialState: {
    list: [],
    count: 0,
  },
  reducers: {
    setEquivalentCodes: (state, { payload: list }) => {
      return {
        ...state,
        list,
      };
    },

    countEquivalentCodes: (state, { payload: count }) => {
      return {
        ...state,
        count,
      };
    },

    deleteEquivalenceCodes: (state, { payload: id }) => {
      return {
        ...state,
        list: state.list.filter((elem) => elem.id !== id),
      };
    },
  },
});

export const { setEquivalentCodes, countEquivalentCodes, deleteEquivalenceCodes } = slice.actions;

export default slice.reducer;

export const getEquivalentCodesSelector = (store) => store.equivalentCodes.list;
export const getEquivalentCodesCountSelector = (store) =>
  store.equivalentCodes.count;
export const deleteEquivalenceCodepeSelector = (store) =>
  store.transportTypes.id;
