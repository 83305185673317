import React, { useEffect, useState } from "react";
import { Card, Input } from "antd";
import SearcherScriptOffsets from "./SearcherScriptOffsets";
import { getUsers } from "../../Users/Application/Users.business";
import ScriptOffsetsBoard from "./ScriptOffsetsBoard";
import { getScriptOffsetByUser } from "../Application/ScriptOffsets.business";
import ScriptOffset from "../../Users/Domain/User";
import RangePicker from "../../Layout/DesignSystem/Picker/Picker";
import "./ScriptOffsets.css";

const ScriptOffsets = () => {
  const [userId, setUserId] = useState(null);
  const [searchQuery, setSearchQuery] = useState("");
  const [values, setValues] = useState({
    id: "",
    start_date: null,
    finish_date: null,
  });

  const onDateChange = (dates, dateStrings) => {
    if (!dates || dates.length === 0) {
      setValues((prev) => ({
        ...prev,
        start_date: null,
        finish_date: null,
        page: 0,
      }));
    } else {
      setValues((prev) => ({
        ...prev,
        start_date: dateStrings[0],
        finish_date: dateStrings[1],
        page: 0,
      }));
    }
  };

  <RangePicker
    onChange={onDateChange}
    format="YYYY-MM-DD"
    style={{ width: "100%" }}
  />;

  const handleInputChange = (e) => {
    const newQuery = e.target.value;
    setSearchQuery(newQuery);

    setValues((prev) => ({
      ...prev,
      page: 0,
    }));
  };

  useEffect(() => {
    getUsers();
    if (userId) {
      getScriptOffsetByUser(userId);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [userId]);
  return (
    <div>
      {" "}
      <div className="grid-scriptOffsets">
        <div className="grid-searcher-script">
          <div>
            <SearcherScriptOffsets setId={setUserId} />
          </div>
          <div className="picker-container">
            <RangePicker onChange={onDateChange} />
          </div>
          <div className="picker-container">
            <Input
              placeholder="N° documento, ID de proveedor, cuenta, área o proyecto"
              value={searchQuery}
              onChange={handleInputChange}
            />{" "}
          </div>
        </div>
        <div></div>
      </div>
      <div className="grid-script">
        <div>
          <Card className="card-content-script">
            {userId && (
              <ScriptOffsetsBoard
                userId={userId}
                ScriptOffset={ScriptOffset}
                getScriptOffsetByUser={getScriptOffsetByUser}
                searchQuery={searchQuery}
                startDate={values.start_date}
                finishDate={values.finish_date}
              />
            )}
          </Card>
        </div>
      </div>
    </div>
  );
};

export default ScriptOffsets;
