import { useEffect, useState } from "react";
import { updateShopifyDistances } from "../Application/ShopifyDistances.business";
import Modal from "../../Modal/Modal";
import { message, Form } from "antd";
import { EditOutlined } from "@ant-design/icons";
import { EditIcon } from "../../Utils/UI/Text";
import "./ShopifyDistances.css";
import { CustomFormItem } from "../../Utils/UI/Text";
import CustomError from "../../Utils/Domain/CustomError";

const ShopifyDistancesDetail = ({ record, refreshData }) => {
  const [values, setValues] = useState(record);
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();

  useEffect(() => {
    setValues(record);
  }, [record]);

  const handleUpdate = async (e) => {
    const data = {
      municipality: values.municipality,
      distance: values.distance,
    };

    try {
      setLoading(true);

      await updateShopifyDistances(record.id, data);
      message.success("ShopifyDistances actualizado con éxito");
      refreshData();
      setLoading(false);
      updateShopifyDistances(record.id, data);
      return true;
    } catch (error) {
      console.log("Error completo:", error);

      const customError = new CustomError(error);
      console.log("Mensaje de error procesado:", customError.message);

      message.error(customError.message);

      return false;
    }
  };

  return (
    <div>
      <Modal
        children={
          <Form
            name="basic"
            autoComplete="off"
            layout="vertical"
            responsive={"true"}
            initialValues={values}
            onFinish={handleUpdate}
            onValuesChange={(_, allValues) => setValues(allValues)}
          >
            {record && (
              <>
                <CustomFormItem
                  label="Municipalidad"
                  name="municipality"
                  placeholder="Ingrese la municipalidad"
                  iconSuffix="🏛️"
                />

                <CustomFormItem
                  label="Distancia"
                  name="distance"
                  placeholder="Ingrese la distancia"
                  iconSuffix="📏"
                />
              </>
            )}
          </Form>
        }
        icon={<EditIcon TooltipEdit="Editar una distancia de Shopify" />}
        title="Editar Distancia de Shopify"
        cancelButtonProps={{ style: { display: "none" } }}
        text="Editar"
        handleSubmit={handleUpdate}
        PlusCircleOutlined={<EditOutlined />}
        form={form}
      />
    </div>
  );
};

export default ShopifyDistancesDetail;
