import React, { useState } from "react";
import { Form, message } from "antd";
import {
  PlusCircleOutlined,
  UserOutlined,
  MailOutlined,
  LockOutlined,
} from "@ant-design/icons";
import { getAdmins } from "../Application/Administrators.business";
import { postAdmin } from "../Infrastructure/Administrator.service";
import Modal from "../../Modal/Modal";
import { AddIcon, CustomFormItem } from "../../Utils/UI/Text";
import CustomError from "../../Utils/Domain/CustomError";

export default function NewAdministrator() {
  const [form] = Form.useForm();
  const [values, setValues] = useState({
    email: "",
    name: "",
    password: "",
  });
  const [formErrors, setFormErrors] = useState({});

  async function handleSubmit() {
    const { password, name, email } = values;

    const passwordRegex =
      /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/;
    if (!passwordRegex.test(password)) {
      setFormErrors({
        password:
          "La contraseña no cumple con los requisitos de seguridad: Mínimo 8 caracteres, al menos una mayúscula, un número y un carácter especial.",
      });
      return;
    }

    try {
      await postAdmin({
        password,
        name,
        email,
      });
      setValues({ ...values, visible: false });
      form.setFieldsValue({ password: "", name: "", email: "" });

      message.success("Se agregó un nuevo Admin");
      getAdmins();
      return true;
    } catch (error) {
      console.log("Error completo:", error);

      const customError = new CustomError(error);
      console.log("Mensaje de error procesado:", customError.message);

      message.error(customError.message);

      if (customError.message.includes("Email already registered")) {
        setFormErrors((prevErrors) => ({
          ...prevErrors,
          email: customError.message,
        }));
      }
      return false;
    }
  }

  return (
    <>
      <Modal
        children={
          <Form
            responsive={"true"}
            layout="vertical"
            onFinish={handleSubmit}
            form={form}
            initialValues={values}
            onValuesChange={(changedValues, allValues) => {
              const updatedValues = { ...allValues };

              if (changedValues.name) {
                updatedValues.name = changedValues.name.replace(
                  /[^A-Za-zÁÉÍÓÚáéíóúÑñ\s]/g,
                  ""
                );
              }

              setValues(updatedValues);
              form.setFieldsValue(updatedValues);
            }}
          >
            <CustomFormItem
              label="Correo Electrónico"
              name="email"
              placeholder="Ingrese su correo electrónico"
              maxLength={50}
              type="email"
              rules={[
                { type: "email", message: "Email no válido" },
                { required: true, message: "Ingresa un valor" },
              ]}
              showCounter
              tooltipText="Ingrese un correo válido. Ejemplo: usuario@correo.com"
              iconSuffix={<MailOutlined />}
              style={{ width: "100%" }}
              validateStatus={formErrors.email ? "error" : ""}
              help={formErrors.email}
            />

            <CustomFormItem
              label="Contraseña"
              name="password"
              placeholder="Ingrese su contraseña"
              maxLength={25}
              inputType="password"
              showCounter
              rules={[
                { required: true, message: "Ingresa un valor" },
                {
                  pattern:
                    /^(?=.*[A-Z])(?=.*\d)(?=.*[@$!%*?&])[A-Za-z\d@$!%*?&]{8,}$/,
                  message:
                    "Debe tener mínimo 8 caracteres, al menos una mayúscula, un número y un carácter especial.",
                },
              ]}
              tooltipText="La contraseña debe tener al menos 8 caracteres, una mayúscula, un número y un símbolo especial."
              iconSuffix={<LockOutlined />}
              style={{ width: "100%" }}
              validateStatus={formErrors.password ? "error" : ""}
              help={formErrors.password}
            />

            <CustomFormItem
              label="Nombre"
              name="name"
              placeholder="Ingrese su nombre"
              maxLength={25}
              rules={[
                {
                  required: true,
                  message: "Nombre no válido",
                  pattern: /^[A-Za-zÁÉÍÓÚáéíóúÑñ\s]+$/,
                },
              ]}
              tooltipText="Ingrese solo letras y espacios."
              iconSuffix={<UserOutlined />}
              showCounter
              style={{ width: "100%" }}
              validateStatus={formErrors.name ? "error" : ""}
              help={formErrors.name}
            />
          </Form>
        }
        icon={<AddIcon />}
        style={{
          marginLeft: "12px",
        }}
        title="Agregar Administrador"
        cancelButtonProps={{ style: { display: "none" } }}
        okButtonProps={{ style: { display: "none" } }}
        text="Agregar"
        handleSubmit={handleSubmit}
        form={form}
        PlusCircleOutlined={<PlusCircleOutlined />}
      />
    </>
  );
}
