import Response from "../../Data/Domain/Response";
import TransportTypes from "../Domain/TransportTypes";
import {
  getTransportTypes,
  editTransportType,
  deleteTransportType,
} from "./TransportTypes.service";

export async function getTransportTypesRepository(params) {
  const data = await getTransportTypes(params);
  return new Response(data, TransportTypes).getMultiple();
}

export async function updateTransportTypeRepository(id, params) {
  const data = await editTransportType(id, params);
  return new Response(data, TransportTypes).onlyStatus();
}

export async function deleteTransportTypeRepository(id) {
  const data = await deleteTransportType(id);
  return new Response(data).onlyStatus();
}
